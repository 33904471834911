import styled from 'styled-components';

export const Wrapper = styled.section`
  text-align: center;
  background-color: #f9f9f9;
  padding: 92px;

  .title {
    margin-top: 48px;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: #323232;
  }
  .description {
    margin-top: 8px;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #787878;
  }
  .link {
    display: inline-block;
    background-color: #323232;
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    color: #ececec;
    text-decoration: none;
    padding: 18px 15px;
    margin-top: 48px;
  }

  @media screen and (max-width: 1240px) {
    padding: 64px 37px;

    .lockImg {
      width: 100%;
      max-width: 217px;
      height: 146px;
    }
    .title {
      width: 186px;
      margin: 40px auto 0;
      font-size: 24px;
      line-height: 30px;
    }
    .description {
      margin-top: 16px;
      font-size: 16px;
      line-height: 24px;
    }
    .link {
      height: 48px;
      display: inline-flex;
      align-items: center;
      padding: 8px 20px;
      background: #2396ff;
      backdrop-filter: blur(16px);
      font-size: 16px;
      line-height: 32px;
      color: #fff;
    }
  }
`;
