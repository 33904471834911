import DesktopLogo from 'assets/Ovey_BI_white.svg';
import WhiteLogo from 'assets/Ovey_BI_white_m.svg';
import { SUPPORT_URL } from '../../constant/url';
import { gtagClickTracking } from '../../utils/gtag';
import { Wrapper, Inner } from './Footer.styled';

const Footer = () => {
  return (
    <Wrapper>
      <Inner>
        <article className="menu">
          <a href="/">
            <img
              className="desktop"
              src={DesktopLogo}
              width="101"
              height="55"
              alt="오베이 흰색 로고"
            />
            <img
              className="mobile"
              src={WhiteLogo}
              width="60"
              height="33"
              alt="오베이 회색 로고"
            />
          </a>
          <ul className="linkWrapper">
            <li>
              <a
                href={SUPPORT_URL}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  gtagClickTracking('링크 접속', SUPPORT_URL);
                }}
              >
                고객센터
              </a>
            </li>
            <li>
              <a
                href="/service-terms"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  gtagClickTracking('링크 접속', 'service-terms');
                }}
              >
                이용약관
              </a>
            </li>
            <li>
              <a
                href="/privacy-terms"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  gtagClickTracking('링크 접속', 'privacy-terms');
                }}
              >
                개인정보처리방침
              </a>
            </li>
          </ul>
        </article>
        <p className="copyright">ⓒ Opensurvey Inc.</p>
        <p className="address">
          서울특별시 강남구 강남대로84길 13 KR타워 12층 (주)오픈서베이
        </p>
      </Inner>
    </Wrapper>
  );
};

export default Footer;
