import { PRIVACY_HISTORY } from 'constant/PrivacyTerm';
import TermsFrame from './TermsFrame';

const PrivacyTerms = () => {
  return (
    <TermsFrame
      title="개인정보처리방침"
      url="privacy-terms"
      historyData={PRIVACY_HISTORY}
    />
  );
};

export default PrivacyTerms;
