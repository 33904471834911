import Screen1 from 'assets/img_screen1.png';
import BackgroundImg1 from 'assets/img_bg1.png';
import Screen2 from 'assets/img_screen2.png';
import BackgroundImg2 from 'assets/img_bg2.png';
import Screen3 from 'assets/img_screen3.png';
import BackgroundImg3 from 'assets/img_bg3.png';
import Screen1M from 'assets/img_screen1_m.png';
import Screen2M from 'assets/img_screen2_m.png';
import Screen3M from 'assets/img_screen3_m.png';
import icon1 from 'assets/ic_1.svg';
import icon2 from 'assets/ic_2.svg';
import icon3 from 'assets/ic_3.svg';
import icon4 from 'assets/ic_4.svg';
import { Wrapper } from './Service.styled';

const Service = () => {
  return (
    <Wrapper>
      <article className="part">
        <article className="imgWrapper desktop">
          <img
            className="mainImg"
            width={402}
            height={722}
            src={Screen1}
            alt="설문 응답 이미지"
          />
          <img
            className="backgroundImg"
            src={BackgroundImg1}
            alt="꾸밈 배경 이미지"
          />
        </article>
        <article className="content">
          <p className="label">내 설문</p>
          <p className="title">간편한 설문 응답</p>
          <p className="description">
            언제 어디서나 손쉽게 설문에 참여해보세요.
          </p>
        </article>
        <article className="mainImg mobile">
          <img src={Screen1M} alt="설문 응답 이미지" />
        </article>
      </article>
      <article className="part">
        <article className="imgWrapper desktop">
          <img
            className="mainImg"
            width={402}
            height={722}
            src={Screen2}
            alt="보상 이미지"
          />
          <img
            className="backgroundImg"
            src={BackgroundImg2}
            alt="꾸밈 배경 이미지"
          />
        </article>
        <article className="content">
          <p className="label">적립률</p>
          <p className="title">점점 커지는 보상</p>
          <p className="description">
            성실히 응답할수록 커지는 적립률을 통해서 더 큰 보상을 드려요.
          </p>
        </article>
        <article className="mainImg mobile">
          <img src={Screen2M} alt="설문 응답 이미지" />
        </article>
      </article>
      <article className="part">
        <article className="imgWrapper desktop">
          <img
            className="mainImg"
            width={402}
            height={722}
            src={Screen3}
            alt="상품 교환 이미지"
          />
          <img
            className="backgroundImg"
            src={BackgroundImg3}
            alt="꾸밈 배경 이미지"
          />
        </article>
        <article className="content">
          <p className="label">오베이샵</p>
          <p className="title">다양한 상품 교환</p>
          <p className="description">
            설문을 통해 적립한 오베이머니로
            <br />
            다양한 상품을 구매하거나 현금으로 교환해보세요.
          </p>
          <ul className="iconList desktop">
            <li className="iconItem">
              <img src={icon1} alt="편의점 아이콘" />
              <p>편의점</p>
            </li>
            <li className="iconItem">
              <img src={icon2} alt="카페 아이콘" />
              <p>카페</p>
            </li>
            <li className="iconItem">
              <img src={icon3} alt="현금 출금 아이콘" />
              <p>현금 출금</p>
            </li>
            <li className="iconItem">
              <img src={icon4} alt="상품권 아이콘" />
              <p>상품권</p>
            </li>
          </ul>
        </article>
        <article className="mainImg mobile">
          <img src={Screen3M} alt="설문 응답 이미지" />
        </article>
        <ul className="iconList mobile">
          <li className="iconItem">
            <article>
              <img src={icon1} width={48} height={48} alt="편의점 아이콘" />
              <p>편의점</p>
            </article>
            <article>
              <img src={icon2} width={48} height={48} alt="카페 아이콘" />
              <p>카페</p>
            </article>
          </li>
          <li className="iconItem">
            <article>
              <img src={icon3} width={48} height={48} alt="현금 출금 아이콘" />
              <p>현금 출금</p>
            </article>
            <article>
              <img src={icon4} width={48} height={48} alt="상품권 아이콘" />
              <p>상품권</p>
            </article>
          </li>
        </ul>
      </article>
    </Wrapper>
  );
};

export default Service;
