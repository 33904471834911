import styled from 'styled-components';

export const Wrapper = styled.nav`
  position: fixed;
  width: 100%;
  padding: 11px 0;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(40px);
  z-index: 100;

  @media screen and (max-width: 1240px) {
    padding: 10px 16px;

    img {
      width: 52px;
      height: 28px;
    }
  }
`;

export const Inner = styled.article`
  max-width: 1140px;
  margin: 0 auto;
`;
