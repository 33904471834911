import { THIRD_PARTY_PRIVACY_TERMS_HISTORY } from 'constant/ThirdPartyPrivacyTerms';
import AppTermsFrame from './AppTermsFrame';

const ThirdPartyPrivacyTerms = () => {
  return (
    <AppTermsFrame
      title="제휴 설문 참여를 위한 개인정보 제3자 제공에 대한 동의"
      url="service-terms"
      historyData={THIRD_PARTY_PRIVACY_TERMS_HISTORY}
    />
  );
};

export default ThirdPartyPrivacyTerms;
