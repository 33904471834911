import styled from 'styled-components';

export const Wrapper = styled.section`
  overflow: hidden;
  padding-bottom: 158px;

  .part {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 1140px;
    margin: 0 auto;

    &:nth-of-type(even) {
      background-color: #f7f8fb;

      .imgWrapper {
        order: 2;
      }
      .content {
        order: 1;
        margin-left: 0;
        margin-right: 165px;
      }
    }
    &:nth-of-type(1) {
      .label {
        color: #309fd0;
      }
      .content {
        width: 415px;
      }
    }
    &:nth-of-type(2) {
      .label {
        color: #94c33b;
      }
      .content {
        width: 409px;
      }
    }
    &:nth-of-type(3) {
      .label {
        color: #288bff;
      }
      .content {
        margin-top: 322px;
        width: 492px;
      }
    }

    .imgWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 566px;
      height: 722px;
    }

    .mainImg {
      position: absolute;
      top: 135px;
      z-index: 1;
    }
    .backgroundImg {
      position: absolute;
    }
    .content {
      margin-top: 120px;
      margin-left: 48px;
      font-weight: 600;
    }
    .label {
      font-size: 26px;
      line-height: 32px;
      letter-spacing: -0.04em;
      color: #309fd0;
    }
    .title {
      margin-top: 4px;
      font-size: 48px;
      line-height: 70px;
      letter-spacing: -0.02em;
      color: #323232;
    }
    .description {
      margin-top: 24px;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.02em;
      color: #787878;
    }
    .iconList {
      margin-top: 69px;
      text-align: center;
      display: flex;
    }
    .iconItem {
      margin-right: 64px;
      font-weight: 400;
      font-size: 18px;
      line-height: 36px;
      color: #787878;

      &:last-of-type {
        margin-right: 0;
      }
      p {
        width: 94px;
      }
    }
  }

  @media screen and (max-width: 1240px) {
    padding-bottom: 0;

    .part {
      padding: 64px 30px;
      min-width: auto;
      display: block;
      text-align: center;

      .label {
        font-size: 16px;
        line-height: 24px;
      }
      .title {
        font-size: 24px;
        line-height: 30px;
      }
      .description {
        font-size: 16px;
        line-height: 24px;
      }
      .content {
        margin: 0 auto 40px auto;
      }
      .mainImg {
        position: initial;

        img {
          width: 100%;
          max-width: 300px;
        }
      }

      &:nth-of-type(1) {
        .content {
          max-width: 183px;
          width: 100%;
        }
      }
      &:nth-of-type(2) {
        .content {
          margin: 0 auto 40px auto;
          width: 100%;
          max-width: 273px;
        }
      }
      &:nth-of-type(3) {
        .content {
          margin-top: 0;
          width: 100%;
          max-width: 217px;
        }
      }
      .iconList {
        display: block;
        margin: 69px auto 0;
        max-width: 200px;

        .iconItem {
          display: flex;
          justify-content: space-between;
          margin: 48px 0 0;

          &:nth-child(1) {
            margin-top: 0;
          }
          &:nth-of-type(even) {
            justify-items: flex-end;
          }
          &:nth-of-type(odd) {
            justify-items: flex-start;
          }
          p {
            width: auto;
            font-size: 15px;
            line-height: 30px;
            color: #288bff;
          }
        }
      }
    }
  }
`;
