export const SERVICE_HISTORY: Record<string, JSX.Element> = {
  '2024.10.31': (
    <div className="terms-body">
      <p className="first_level">제 1 장 총칙</p>
      <p className="second_level">제 1 조 (목적)</p>이 약관은 주식회사
      오픈서베이(이하 &quot;회사&quot;)가 제공하는 OVEY 및 OPENSURVEY
      서비스(이하 &quot;서비스&quot;)의 이용과 관련하여 회사와 회원과의 권리,
      의무 및 책임사항을 규정함을 목적으로 합니다.
      <p className="second_level">제 2 조 (정의)</p>이 약관에서 사용하는 용어의
      정의는 다음과 같습니다.
      <ol className="terms">
        <li>
          &quot;서비스&quot;라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의
          각종 유무선 장치를 포함)와 상관없이 &quot;회원&quot;이 이용할 수 있는
          오베이 및 오베이 관련 제반 서비스를 의미합니다.
        </li>
        <li>
          &quot;회원&quot;이라 함은 회사의 &quot;서비스&quot;에 접속하여 이
          약관에 따라 &quot;회사&quot;와 이용계약을 체결하고 &quot;회사&quot;가
          제공하는 &quot;서비스&quot;를 이용하는 이용자를 말합니다.
        </li>
        <li>
          &quot;비회원&quot;이라 함은 회원에 가입하지 않고 &quot;회사&quot;가
          제공하는 &quot;서비스&quot;를 이용하는 자를 말합니다.
        </li>
        <li>
          &quot;애플리케이션&quot;이라 함은 iOS, Android 등의 모바일 운영체제에
          탑재되어 있는 앱스토어를 통해 단말기에 설치하여 이용할 수 있도록
          구성된 프로그램을 말합니다.
        </li>
        <li>
          &quot;의뢰자&quot;는 &quot;회원&quot;이 &quot;서비스&quot;를 통해
          참여하는 조사의 의뢰 주체를 의미합니다.
        </li>
        <li>
          &quot;계정&quot;이라 함은 &quot;회원&quot;의 식별과
          &quot;서비스&quot;이용을 위하여 &quot;회원&quot;이 인증하고
          &quot;회사&quot;가 승인하는 휴대전화번호를 의미합니다.
        </li>
        <li>
          &quot;게시물&quot;이라 함은 &quot;회원&quot;이 &quot;서비스&quot;를
          이용함에 있어 &quot;회원&quot;이 &quot;서비스&quot;에 게시한 문자,
          문서, 그림, 음성, 링크, 파일 혹은 이들의 조합으로 이루어진 정보 등
          모든 정보나 자료를 의미합니다.
        </li>
        <li>
          &quot;오베이머니&quot;라 함은 &quot;회원&quot;이 &quot;서비스&quot;에
          종속된 활동을 통해 부여받은 것으로, 현금으로 출금 또는
          &quot;제휴컨텐츠&quot; 이용에 사용할 수 있는 가상의 화폐를 의미합니다.
        </li>
        <li>
          &quot;제휴컨텐츠&quot;라 함은 &quot;회사&quot;가 제휴를 통하여
          &quot;회원&quot;이 &quot;오베이머니&quot;를 지급하고 사용할 수 있는
          다양한 서비스를 의미합니다.
        </li>
      </ol>
      <p className="second_level">제 3 조 (약관의 명시와 개정)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;는 이 약관의 내용을 이용자가 쉽게 알 수 있도록 서비스
          초기 화면(전면)에 게시합니다.
        </li>
        <li>
          &quot;회사&quot;는 &quot;약관의 규제에 관한 법률&quot;,
          &quot;정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하
          &quot;정보통신망법&quot;)&quot; 등 관련법을 위배하지 않는 범위에서 이
          약관을 개정할 수 있습니다.
        </li>
        <li>
          &quot;회사&quot;는 약관을 개정할 경우, 적용일자 및 개정사유를 명시하여
          현행약관과 함께 사이트의 초기화면에 그 적용일 7일 이전부터 적용일자
          전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는
          경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우
          &quot;회사&quot;는 개정 전 내용과 개정 후 내용을 명확하게 비교하여
          이용자가 알기 쉽도록 표시합니다.
        </li>
        <li>
          &quot;회사&quot;가 개정약관을 공지 또는 통지하면서
          &quot;회원&quot;에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가
          표명된 것으로 본다는 뜻을 명확하게 공지 또는 고지하였음에도
          &quot;회원&quot;이 명시적으로 거부의 의사표시를 하지 아니한 경우,
          &quot;회원&quot;이 개정약관에 동의한 것으로 봅니다.
        </li>
        <li>
          &quot;회원&quot;이 개정약관의 적용에 동의하지 않는 경우
          &quot;회사&quot;는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은
          이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한
          사정이 있는 경우에는 &quot;회사&quot;는 이용계약을 해지할 수 있습니다.
        </li>
        <li>
          본 약관에서 명시하지 않은 사항과 본 약관의 해석에 관하여는 관계법령
          또는 상관례에 따릅니다.
        </li>
      </ol>
      <p className="first_level">제 2 장 서비스 계약의 체결</p>
      <p className="second_level">제 4 조 (서비스 이용계약 체결)</p>
      <ol className="terms">
        <li>
          &quot;서비스&quot; 이용계약은 아래의 방법으로 체결이 가능합니다.
          <ol className="sub">
            <li>
              &quot;회원&quot;이 되고자 하는 자(이하 &quot;가입신청자&quot;)가
              이용약관에 동의를 하고 &quot;회사&quot;에서 요구하는 정보를 입력한
              경우 &quot;회사&quot;가 이러한 신청에 대하여 승낙함으로써
              체결됩니다.
            </li>
          </ol>
        </li>
        <li>
          &quot;회사&quot;는 다음 각 호에 해당하는 신청에 대하여 승낙을 하지
          않거나, 사후에 통보 없이 이용계약을 해지할 수 있습니다.
          <ol className="sub">
            <li>
              가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는
              경우 (다만, 회원자격 상실 후 1년이 경과한 자로서
              &quot;회사&quot;의 회원 재가입 승낙을 얻은 경우에는 예외로 함)
              <li>본인 계정이 아닌 타인 계정을 이용한 경우</li>
              <li>
                &quot;오베이머니&quot;를 부정한 방법으로 적립하거나 사용한 경우
              </li>
              <li>
                허위의 정보를 기재하거나, &quot;서비스&quot;에서 제시하는 내용을
                기재하지 않은 경우
              </li>
              <li>가입신청자가 만 14세 미만 아동인 경우</li>
              <li>
                이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반
                사항을 위반하며 신청하는 경우
              </li>
            </li>
          </ol>
        </li>
        <li>
          제1항에 따른 신청에 있어 &quot;회사&quot;는 &quot;회원&quot;의 종류에
          따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
        </li>
        <li>
          실제 휴대전화의 소유주임을 확인하기 위해서 회원가입 당시 휴대전화번호
          인증절차를 거치게 됩니다.
        </li>
        <li>
          다른 휴대전화에서 &quot;서비스&quot; 사용을 연속하기 위해서 회원가입
          당시 휴대전화번호 인증절차를 거치게 됩니다.
        </li>
        <li>
          다음의 경우에는 &quot;계정&quot; 생성이 승인되지 않을 수 있습니다.
          <ol className="sub">
            <li>
              다른 사람의 명의나 휴대전화번호 등 개인정보를 이용하여
              &quot;계정&quot;을 생성하는 행위
            </li>
            <li>동일인이 다수의 &quot;계정&quot;을 생성하는 행위</li>
            <li>
              &quot;계정&quot; 생성 시 필요한 정보를 입력하지 않거나 허위 정보를
              입력하는 행위
            </li>
            <li>
              과거에 &quot;서비스&quot;의 운영원칙 또는 법률 위반 등의 정당한
              사유로 해당 &quot;계정&quot;이 삭제 또는 징계된 경우
            </li>
          </ol>
        </li>
        <li>
          &quot;회사&quot;는 &quot;서비스&quot; 관련설비의 여유가 없거나, 기술상
          또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
        </li>
        <li>
          제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한
          경우, &quot;회사&quot;는 원칙적으로 이를 가입신청자에게 알리도록
          합니다.
        </li>
        <li>
          이용계약의 성립 시기는 &quot;회사&quot;가 가입완료를 신청절차 상에서
          표시한 시점으로 합니다.
        </li>
        <li>
          &quot;회사&quot;는 &quot;회원&quot;에 대해 회사정책에 따라 등급별로
          구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을
          둘 수 있습니다.
        </li>
      </ol>
      <p className="second_level">제 5 조 (회원정보의 변경)</p>
      <ol className="terms">
        <li>
          &quot;회원&quot;은 프로필 정보 수정 화면을 통하여 언제든지 본인의
          개인정보를 열람하고 수정할 수 있습니다. 다만, &quot;서비스&quot;
          관리를 위해 필요한 생년월일, 성별은 수정이 불가능합니다.
        </li>
        <li>
          &quot;회원&quot;은 생년월일, 성별을 오기재한 경우 고객센터 문의를 통해
          수정할 수 있습니다.
        </li>
        <li>
          &quot;회원&quot;은 회원가입신청 시 기재한 사항이 변경되었을 경우
          온라인으로 수정을 하거나 전자우편 기타 방법으로 &quot;회사&quot;에
          대하여 그 변경사항을 알려야 합니다.
        </li>
        <li>
          제2항의 변경사항을 &quot;회사&quot;에 알리지 않아 발생한 불이익에
          대하여 &quot;회사&quot;는 책임지지 않습니다.
        </li>
        <li>
          &quot;회원&quot;은 언제든지 고객센터 또는 회원 정보 수정 메뉴 등을
          통하여 이용계약 해지 신청(회원탈퇴)을 할 수 있습니다.
          &quot;회원&quot;이 회원탈퇴를 신청한 경우 회사는 회원 본인 여부를
          확인할 수 있으며, 관계법규 등이 정하는 바에 따라 이를 즉시 처리합니다.
          단, &quot;회사&quot;는 서비스 운영 원칙에 따라 30일 동안 재가입을
          제한할 수 있습니다.
        </li>
        <li>
          &quot;회원&quot;이 계약을 해지할 경우, 관련법 및 개인정보처리방침에
          따라 &quot;회사&quot;가 회원정보를 보유하는 경우를 제외하고는 해지
          즉시 &quot;회원&quot;의 모든 데이터는 소멸됩니다.
        </li>
        <li>
          &quot;회원&quot;이 연속하여 3년 동안 서비스에 log-in한 기록이 없는
          경우 회원 자격을 상실할 수 있습니다.
        </li>
      </ol>
      <p className="second_level">제 6 조 (개인정보보호 의무)</p>
      &quot;회사&quot;는 &quot;개인정보 보호법&quot; 등 관계 법령이 정하는 바에
      따라 &quot;회원&quot;의 개인정보를 보호하기 위해 노력합니다. 개인정보의
      보호 및 사용에 대해서는 관련법 및 &quot;회사&quot;의 개인정보처리방침이
      적용됩니다. 다만, &quot;회사&quot;의 공식 사이트 이외의 링크된
      사이트에서는 &quot;회사&quot;의 개인정보처리방침이 적용되지 않습니다.
      <br />
      &quot;회원&quot;이 작성한 &quot;게시물&quot; 중 설문응답 결과가 포함된
      정보는 &quot;회원&quot;이 동의한 바와 같이 &quot;회원&quot;이 가입 탈퇴를
      한 때로부터 5년간 &quot;회사&quot;의 통계분석 등의 목적과 이익에 따라
      이용할 수 있고, 탈퇴 후 5년이 경과한 후에는 해당 정보를 관련 법령에 따라
      가명처리 또는 익명처리하여 &quot;회사&quot;의 목적과 이익에 따라
      이용합니다.
      <p className="second_level">
        제 7 조 (&quot;회원&quot;의 &quot;계정&quot; 관리에 대한 의무)
      </p>
      <ol className="terms">
        <li>
          &quot;회원&quot;은 &quot;계정&quot;에 관한 관리책임은
          &quot;회원&quot;에게 있으며, 이를 제3자가 이용하도록 하여서는
          안됩니다.
        </li>
        <li>
          &quot;회원&quot;은 &quot;계정&quot;이 도용되거나 제3자가 사용하고
          있음을 인지한 경우에는 이를 즉시 &quot;회사&quot;에 통지하고
          &quot;회사&quot;의 안내에 따라야 합니다.
        </li>
        <li>
          제6항의 경우에 해당 &quot;회원&quot;이 &quot;회사&quot;에 그 사실을
          통지하지 않거나, 통지한 경우에도 &quot;회사&quot;의 안내에 따르지 않아
          발생한 불이익에 대하여 &quot;회사&quot;는 책임지지 않습니다.
        </li>
      </ol>
      <p className="second_level">제 8 조 (&quot;회사&quot;의 의무)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;는 법령과 이 약관이 금지하거나 공서양속에 반하는
          행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로
          재화, 용역을 제공하는 데 최선을 다하여야 합니다.
        </li>
        <li>
          &quot;회사&quot;는 이용자가 안전하게 &quot;서비스&quot;를 이용할 수
          있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을
          갖추어 이용자의 개인정보보호에 최선을 다하여야 합니다.
        </li>
        <li>
          &quot;회사&quot;는 판매하는 상품이나 용역에 대하여
          &quot;표시ᆞ광고의공정화에관한법률&quot; 제3조의 규정에 위반하는 표시
          및 광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을
          부담합니다.
        </li>
        <li>
          &quot;회사&quot;는 수신거절의 의사를 명확히 표시한 이용자에 대해서는
          영리목적의 광고성 문자메시지 및 전자우편을 발송하지 않습니다.
        </li>
      </ol>
      <p className="second_level">제 9 조 (&quot;회원&quot;의 의무)</p>
      <ol className="terms">
        <li>
          &quot;회원&quot;은 다음 행위를 하여서는 안 됩니다.
          <ol className="sub">
            <li>개인정보의 등록(변경의 경우 포함함)시 허위내용을 등록</li>
            <li>의도적으로 동시에 두 개 이상의 계정을 사용</li>
            <li>&quot;오베이머니&quot;를 부정한 방법으로 적립하거나 사용</li>
            <li>&quot;회사&quot;에 게시된 정보를 임의로 변경</li>
            <li>
              &quot;회사&quot;가 허락하지 않은 정보(컴퓨터 프로그램 등)의 송신
              또는 게시
            </li>
            <li>
              &quot;회사&quot; 기타 제3자의 저작권 등 지적재산권에 대한 침해
            </li>
            <li>
              &quot;회사&quot; 기타 제3자의 명예를 손상시키거나 업무를 방해하는
              행위
            </li>
            <li>
              외설 또는 폭력적인 메시지, 화상, 음성 기타 공서양속에 반하는
              정보를 화면에 공개 또는 게시하는 행위
            </li>
            <li>
              불특정 다수의 자를 대상으로 &quot;회사&quot;의
              &quot;서비스&quot;를 이용하여 영리목적으로 활동하는 행위
            </li>
            <li>
              사실관계를 왜곡하는 정보제공 행위, 기타 &quot;회사&quot;가
              부적절하다고 판단하는 행위
            </li>
            <li>타인의 정보 도용</li>
          </ol>
        </li>
        <li>
          &quot;회원&quot;은 관계법, 이 약관의 규정, 이용안내 및
          &quot;서비스&quot;와 관련하여 공지한 주의사항, &quot;회사&quot;가
          통지하는 사항 등을 준수하여야 하며, 기타 &quot;회사&quot;의 업무에
          방해되는 행위를 하여서는 안 됩니다. 만약 이를 위반할 경우 회원자격이
          박탈될 수 있습니다.
        </li>
      </ol>
      <p className="first_level">제 3 장 서비스의 내용과 이용</p>
      <p className="second_level">제 10 조 (&quot;서비스&quot;의 내용)</p>
      &quot;회사&quot;는 &quot;회원&quot;에게 아래와 같은 서비스를 제공합니다.
      <ol className="terms">
        <li>
          &quot;회사&quot;는 &quot;회원&quot;에게 아래와 같은 서비스를
          제공합니다.
          <ol className="sub">
            <li>
              설문조사에 대해 응답할 수 있는 자격과 이에 따른
              &quot;오베이머니&quot; 제공
            </li>
            <li>
              &quot;오베이머니&quot;를 현금으로 출금 또는
              &quot;제휴컨텐츠&quot;로 교환
            </li>
            <li>이외 제공하는 모든 부가 서비스</li>
          </ol>
        </li>
      </ol>
      <p className="second_level">제 11 조 (&quot;서비스&quot;의 이용)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;는 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의
          경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을
          변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및
          제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시
          공지합니다. 단, &quot;회사&quot;가 합리적으로 예측할 수 없는 불가피한
          여건이나 사정이 있는 경우, 위 공지기간을 단축할 수 있습니다.
        </li>
        <li>
          &quot;회사&quot;가 제공하기로 이용자와 계약을 체결한
          &quot;서비스&quot;의 내용을 재화 등의 품절 또는 기술적 사양의 변경
          등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로
          즉시 통지합니다.
        </li>
        <li>
          전항의 경우 &quot;회사&quot;는 이로 인하여 이용자가 입은 손해를
          배상합니다. 다만, &quot;회사&quot;가 고의 또는 과실이 없음을 입증하는
          경우에는 아무런 책임을 부담하지 않습니다. &quot;회사&quot;는 컴퓨터 등
          정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한
          이유가 있는 경우 &quot;서비스&quot;의 제공을 일시적으로 중단할 수
          있습니다. 다만, &quot;회사&quot;가 사전에 통지할 수 없는 부득이한
          사유가 있는 경우 사후에 통지할 수 있습니다.
        </li>
        <li>
          &quot;회사&quot;는 서비스의 제공에 필요한 경우 정기점검을 실시할 수
          있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.
        </li>
      </ol>
      <p className="second_level">제 12 조 (&quot;서비스&quot;의 변경)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에
          따라 제공하고 있는 전부 또는 일부 &quot;서비스&quot;를 변경할 수
          있습니다.
        </li>
        <li>
          &quot;서비스&quot;의 내용, 이용방법, 이용시간에 대하여 변경이 있는
          경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에
          미리 내용을 초기화면에 게시하여야 합니다.
        </li>
        <li>
          &quot;회사&quot;는 무료로 제공되는 &quot;서비스&quot;의 일부 또는
          전부를 &quot;회사&quot;의 정책 및 운영의 필요상 수정, 중단, 변경할 수
          있으며, 이에 대하여 관련법에 특별한 규정이 없는 한
          &quot;회원&quot;에게 별도의 보상을 하지 않습니다.
        </li>
      </ol>
      <p className="second_level">제 13 조 (정보의 제공 및 광고의 게재)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;는 &quot;회원&quot;이 &quot;서비스&quot; 이용 중
          필요하다고 인정되는 다양한 정보를 공지사항, 전자우편 등의 방법으로
          &quot;회원&quot;에게 제공할 수 있습니다. 다만, &quot;회원&quot;은
          관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는
          언제든지 전자우편 등에 대해서 수신 거절을 할 수 있습니다.
        </li>
        <li>
          제1항의 정보를 전화 및 팩스전송기기에 의하여 전송하려고 하는 경우에는
          &quot;회원&quot;의 사전 동의를 받아서 전송합니다. 다만,
          &quot;회원&quot;의 거래관련 정보 및 고객문의 등에 대한 회신에 있어서는
          제외됩니다.
        </li>
        <li>
          &quot;회사&quot;는 &quot;서비스&quot;의 운영과 관련하여
          &quot;서비스&quot; 화면, 홈페이지, 전자우편 등에 광고를 게재할 수
          있습니다. 광고가 게재된 전자우편 등을 수신한 &quot;회원&quot;은
          수신거절을 &quot;회사&quot;에게 할 수 있습니다.
        </li>
        <li>
          &quot;회원&quot;의 정보는 &quot;의뢰자&quot;에게 아래와 같은 기준에
          의해 제공됩니다.
          <ol className="sub">
            <li>
              &quot;회사&quot;는 &quot;회원&quot;의 설문응답 결과와
              &quot;회원&quot;이 설문조사에 응할 당시의 일부 정보(성별, 나이,
              직업, 지역에 한하며, 이름이나 연락처 등의 정보는 포함되지 않음)을
              &quot;의뢰자&quot;에게 제공하고, 해당 정보는 설문조사의 결과에
              대한 통계 조사 자료로만 활용됩니다. &quot;회사&quot;는 이와 같은
              &quot;회원&quot; 정보를 제공함에 있어서 &quot;회원&quot; 으로부터
              개인정보 보호법에서 정하는 개인정보 제공 동의를 받는 등 관련
              법령에서 정하는 절차를 준수합니다.
            </li>
            <li>
              &quot;회원&quot;에 대하여 &quot;의뢰자&quot;가 인터뷰 또는 개별
              연락을 원할 경우, &quot;회원&quot;과 &quot;회사&quot;의 동의 하에
              모든 과정이 진행됩니다.
            </li>
            <li>
              &quot;의뢰자&quot;는 &quot;회사&quot;로부터 &quot;회원&quot;의
              개인정보를 제공받지 않고 직접 &quot;회원&quot;으로부터 동의를 받아
              &quot;회원&quot;의 개인정보를 수집하여 이용할 수 있으며, 이러한
              처리는 &quot;회사&quot;와 무관하며 해당 &quot;회원&quot;의
              개인정보 처리에 관한 법령 등 준수의 책임은 &quot;의뢰자&quot;에게
              있습니다.
            </li>
          </ol>
        </li>
      </ol>
      <p className="second_level">
        제 14 조 (&quot;회원&quot;의 &quot;오베이머니&quot; 출금 관련 정책)
      </p>
      <ol className="terms">
        <li>
          &quot;오베이머니&quot;를 10,000원 이상 보유한 &quot;회원&quot;은
          보유한 &quot;오베이머니&quot;의 현금출금을 신청 할 수 있습니다.
          &quot;회사&quot;가 정한 소정절차에 따라 &quot;오베이머니&quot;를
          현금으로 환산한 금액을 지급 받을 수 있으며, 이 경우 &quot;회사&quot;는
          &quot;회원&quot;이 지정하는 은행계좌에 상기 금액을 입금함으로써
          적법하게 지급한 것으로 봅니다.
        </li>
        <li>
          &quot;오베이머니&quot;의 사용 및 현금출금과 관련하여
          &quot;오베이머니&quot;는 일 오베이머니(1 오베이머니)당 일 원(1 원)으로
          환산함을 원칙으로 합니다.
        </li>
        <li>
          &quot;오베이머니&quot;의 현금출금에 따라 발생하는 제세공과금은
          &quot;회원&quot;이 부담합니다.
        </li>
        <li>
          &quot;회사&quot;의 &quot;제휴컨텐츠&quot;를 &quot;오베이머니&quot;로
          구매 시 해당 바코드가 전송됨으로써 적법하게 지급한 것으로 봅니다.
        </li>
        <li>
          &quot;회원&quot;은 &quot;오베이머니&quot;를 타인에게 양도하거나 대여
          또는 담보의 목적으로 이용할 수 없습니다. 다만, &quot;회사&quot;가
          인정하는 적법한 절차를 따른 경우는 예외로 합니다.
        </li>
        <li>
          &quot;오베이머니&quot;를 사용할 경우, 먼저 적립 된
          &quot;오베이머니&quot;가 먼저 사용됩니다.
        </li>
        <li>
          &quot;회원&quot;에게 지급된 &quot;오베이머니&quot;의 유효기간은
          3년으로 하며, 3년의 유효기간이 지난 &quot;오베이머니&quot;는
          자동적으로 소멸됩니다. 다만 &quot;오베이머니&quot;의 자동소멸은 2012년
          5월 11일 이후 발생한 &quot;오베이머니&quot;에 한해 적용됩니다.
        </li>
      </ol>
      <p className="second_level">
        제 15 조 (&quot;서비스&quot; 권리의 귀속 및 &quot;게시물&quot;의 이용)
      </p>
      <ol className="terms">
        <li>
          &quot;회원&quot;의 &quot;게시물&quot;이 &quot;정보통신망법&quot; 및
          &quot;저작권법&quot;등 관련법에 위반되는 내용을 포함하는 경우,
          권리자는 관련법이 정한 절차에 따라 해당 &quot;게시물&quot;의 게시중단
          및 삭제 등을 요청할 수 있으며, &quot;회사&quot;는 관련법에 따라 조치를
          취하여야 합니다.
        </li>
        <li>
          &quot;회사&quot;는 전항에 따른 권리자의 요청이 없는 경우라도
          권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에
          위반되는 경우에는 관련법에 따라 해당 &quot;게시물&quot;에 대해
          임시조치 등을 취할 수 있습니다.
        </li>
        <li>
          설문조사 결과를 비롯하여 &quot;서비스&quot; 및 “서비스”로부터 발생한
          결과물에 대한 소유권 및 지적재산권(저작권 포함)은 &quot;회사&quot;에
          귀속됩니다. 단, 제휴계약에 따라 제공된 저작물 등은 제외합니다.
        </li>
        <li>
          &quot;회사&quot;는 서비스와 관련하여 &quot;회원&quot;에게
          &quot;회사&quot;가 정한 이용조건에 따라 &quot;계정&quot;,
          &quot;콘텐츠&quot;, &quot;오베이머니&quot; 등을 이용할 수 있는
          이용권만을 부여하며, &quot;회원&quot;은 이를 양도, 판매, 담보제공 등의
          처분행위를 할 수 없습니다.
        </li>
      </ol>
      <p className="second_level">제 16 조 (&quot;애플리케이션&quot;의 이용)</p>
      <ol className="terms">
        <li>
          &quot;회원&quot;은 모바일 단말기 제조사 및 이동통신사에서 제공하는
          앱스토어를 통해 &quot;애플리케이션&quot;을 설치하여
          &quot;서비스&quot;를 이용할 수 있습니다.
        </li>
        <li>
          &quot;회원&quot;이 &quot;서비스&quot;의 일부 또는 이용규칙을 적용하는
          데에 사용되는 보안 기술이나 소프트웨어를 회피 또는 변경하려 시도를
          하거나 부정 사용 및 타인이 그렇게 하는 것을 조장하는 행위 등은
          금지되어 있으며, 만약 &quot;회원&quot;이 그와 같은 행위를 하는 경우
          이에 대한 모든 책임은 &quot;회원&quot; 본인에게 있습니다.
        </li>
      </ol>
      <p className="first_level">제 4 장 기타</p>
      <p className="second_level">제 17 조 (책임제한)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;는 천재지변 또는 이에 준하는 불가항력으로 인하여
          &quot;서비스&quot;를 제공할 수 없는 경우에는 &quot;서비스&quot; 제공에
          관한 책임이 면제됩니다.
        </li>
        <li>
          &quot;회사&quot;는 &quot;회원&quot;의 귀책사유로 인한
          &quot;서비스&quot; 이용의 장애에 대하여는 책임을 지지 않습니다.
        </li>
        <li>
          &quot;회사&quot;는 무료로 제공되는 &quot;서비스&quot; 이용과 관련하여
          관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.
        </li>
      </ol>
      <p className="second_level">제 18 조 (준거법 및 재판관할)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;와 &quot;회원&quot; 간 제기된 소송은 대한민국법을
          준거법으로 합니다.
        </li>
        <li>
          &quot;회사&quot;와 &quot;회원&quot;간 발생한 분쟁에 관한 소송은
          민사소송법 상의 관할법원에 제소합니다.
        </li>
      </ol>
      <p className="second_level">제 19 조 (사업자 정보)</p>
      <ol className="terms">
        <li>회사명: 주식회사 오픈서베이</li>
        <li>대표자: 황희영</li>
        <li>개인정보관리 책임자: 이건노</li>
        <li>주소: 서울특별시 강남구 강남대로84길 13 KR타워 12층</li>
        <li>대표전화: 02-2070-2110</li>
      </ol>
      <br />
      <p>- 공고일자: 2022년 10월 24일</p>
      <p className="second_level">[부칙]</p>
      <ol className="terms">
        <li>이 약관은 2024년 10월 31일부터 시행합니다.</li>
      </ol>
    </div>
  ),
  '2022.10.31~2024.10.30': (
    <div className="terms-body">
      <p className="first_level">제 1 장 총칙</p>
      <p className="second_level">제 1 조 (목적)</p>이 약관은 주식회사
      오픈서베이(이하 &quot;회사&quot;)가 제공하는 OVEY 및 OPENSURVEY
      서비스(이하 &quot;서비스&quot;)의 이용과 관련하여 회사와 회원과의 권리,
      의무 및 책임사항을 규정함을 목적으로 합니다.
      <p className="second_level">제 2 조 (정의)</p>이 약관에서 사용하는 용어의
      정의는 다음과 같습니다.
      <ol className="terms">
        <li>
          &quot;서비스&quot;라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의
          각종 유무선 장치를 포함)와 상관없이 &quot;회원&quot;이 이용할 수 있는
          오베이 및 오베이 관련 제반 서비스를 의미합니다.
        </li>
        <li>
          &quot;회원&quot;이라 함은 회사의 &quot;서비스&quot;에 접속하여 이
          약관에 따라 &quot;회사&quot;와 이용계약을 체결하고 &quot;회사&quot;가
          제공하는 &quot;서비스&quot;를 이용하는 이용자를 말합니다.
        </li>
        <li>
          &quot;비회원&quot;이라 함은 회원에 가입하지 않고 &quot;회사&quot;가
          제공하는 &quot;서비스&quot;를 이용하는 자를 말합니다.
        </li>
        <li>
          &quot;애플리케이션&quot;이라 함은 iOS, Android 등의 모바일 운영체제에
          탑재되어 있는 앱스토어를 통해 단말기에 설치하여 이용할 수 있도록
          구성된 프로그램을 말합니다.
        </li>
        <li>
          &quot;의뢰자&quot;는 &quot;회원&quot;이 &quot;서비스&quot;를 통해
          참여하는 조사의 의뢰 주체를 의미합니다.
        </li>
        <li>
          &quot;계정&quot;이라 함은 &quot;회원&quot;의 식별과
          &quot;서비스&quot;이용을 위하여 &quot;회원&quot;이 인증하고
          &quot;회사&quot;가 승인하는 휴대전화번호를 의미합니다.
        </li>
        <li>
          &quot;게시물&quot;이라 함은 &quot;회원&quot;이 &quot;서비스&quot;를
          이용함에 있어 &quot;회원&quot;이 &quot;서비스&quot;에 게시한 문자,
          문서, 그림, 음성, 링크, 파일 혹은 이들의 조합으로 이루어진 정보 등
          모든 정보나 자료를 의미합니다.
        </li>
        <li>
          &quot;오베이머니&quot;라 함은 &quot;회원&quot;이 &quot;서비스&quot;에
          종속된 활동을 통해 부여받은 것으로, 현금으로 출금 또는
          &quot;제휴컨텐츠&quot; 이용에 사용할 수 있는 가상의 화폐를 의미합니다.
        </li>
        <li>
          &quot;제휴컨텐츠&quot;라 함은 &quot;회사&quot;가 제휴를 통하여
          &quot;회원&quot;이 &quot;오베이머니&quot;를 지급하고 사용할 수 있는
          다양한 서비스를 의미합니다.
        </li>
      </ol>
      <p className="second_level">제 3 조 (약관의 명시와 개정)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;는 이 약관의 내용을 이용자가 쉽게 알 수 있도록 서비스
          초기 화면(전면)에 게시합니다.
        </li>
        <li>
          &quot;회사&quot;는 &quot;약관의 규제에 관한 법률&quot;,
          &quot;정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하
          &quot;정보통신망법&quot;)&quot; 등 관련법을 위배하지 않는 범위에서 이
          약관을 개정할 수 있습니다.
        </li>
        <li>
          &quot;회사&quot;는 약관을 개정할 경우, 적용일자 및 개정사유를 명시하여
          현행약관과 함께 사이트의 초기화면에 그 적용일 7일 이전부터 적용일자
          전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는
          경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우
          &quot;회사&quot;는 개정 전 내용과 개정 후 내용을 명확하게 비교하여
          이용자가 알기 쉽도록 표시합니다.
        </li>
        <li>
          &quot;회사&quot;가 개정약관을 공지 또는 통지하면서
          &quot;회원&quot;에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가
          표명된 것으로 본다는 뜻을 명확하게 공지 또는 고지하였음에도
          &quot;회원&quot;이 명시적으로 거부의 의사표시를 하지 아니한 경우,
          &quot;회원&quot;이 개정약관에 동의한 것으로 봅니다.
        </li>
        <li>
          &quot;회원&quot;이 개정약관의 적용에 동의하지 않는 경우
          &quot;회사&quot;는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은
          이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한
          사정이 있는 경우에는 &quot;회사&quot;는 이용계약을 해지할 수 있습니다.
        </li>
        <li>
          본 약관에서 명시하지 않은 사항과 본 약관의 해석에 관하여는 관계법령
          또는 상관례에 따릅니다.
        </li>
      </ol>
      <p className="first_level">제 2 장 서비스 계약의 체결</p>
      <p className="second_level">제 4 조 (서비스 이용계약 체결)</p>
      <ol className="terms">
        <li>
          &quot;서비스&quot; 이용계약은 아래의 방법으로 체결이 가능합니다.
          <ol className="sub">
            <li>
              &quot;회원&quot;이 되고자 하는 자(이하 &quot;가입신청자&quot;)가
              이용약관에 동의를 하고 &quot;회사&quot;에서 요구하는 정보를 입력한
              경우 &quot;회사&quot;가 이러한 신청에 대하여 승낙함으로써
              체결됩니다.
            </li>
          </ol>
        </li>
        <li>
          &quot;회사&quot;는 다음 각 호에 해당하는 신청에 대하여 승낙을 하지
          않거나, 사후에 통보 없이 이용계약을 해지할 수 있습니다.
          <ol className="sub">
            <li>
              가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는
              경우 (다만, 회원자격 상실 후 1년이 경과한 자로서
              &quot;회사&quot;의 회원 재가입 승낙을 얻은 경우에는 예외로 함)
              <li>본인 계정이 아닌 타인 계정을 이용한 경우</li>
              <li>
                &quot;오베이머니&quot;를 부정한 방법으로 적립하거나 사용한 경우
              </li>
              <li>
                허위의 정보를 기재하거나, &quot;서비스&quot;에서 제시하는 내용을
                기재하지 않은 경우
              </li>
              <li>가입신청자가 만 14세 미만 아동인 경우</li>
              <li>
                이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반
                사항을 위반하며 신청하는 경우
              </li>
            </li>
          </ol>
        </li>
        <li>
          제1항에 따른 신청에 있어 &quot;회사&quot;는 &quot;회원&quot;의 종류에
          따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
        </li>
        <li>
          실제 휴대전화의 소유주임을 확인하기 위해서 회원가입 당시 휴대전화번호
          인증절차를 거치게 됩니다.
        </li>
        <li>
          다른 휴대전화에서 &quot;서비스&quot; 사용을 연속하기 위해서 회원가입
          당시 휴대전화번호 인증절차를 거치게 됩니다.
        </li>
        <li>
          다음의 경우에는 &quot;계정&quot; 생성이 승인되지 않을 수 있습니다.
          <ol className="sub">
            <li>
              다른 사람의 명의나 휴대전화번호 등 개인정보를 이용하여
              &quot;계정&quot;을 생성하는 행위
            </li>
            <li>동일인이 다수의 &quot;계정&quot;을 생성하는 행위</li>
            <li>
              &quot;계정&quot; 생성 시 필요한 정보를 입력하지 않거나 허위 정보를
              입력하는 행위
            </li>
            <li>
              과거에 &quot;서비스&quot;의 운영원칙 또는 법률 위반 등의 정당한
              사유로 해당 &quot;계정&quot;이 삭제 또는 징계된 경우
            </li>
          </ol>
        </li>
        <li>
          &quot;회사&quot;는 &quot;서비스&quot; 관련설비의 여유가 없거나, 기술상
          또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
        </li>
        <li>
          제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한
          경우, &quot;회사&quot;는 원칙적으로 이를 가입신청자에게 알리도록
          합니다.
        </li>
        <li>
          이용계약의 성립 시기는 &quot;회사&quot;가 가입완료를 신청절차 상에서
          표시한 시점으로 합니다.
        </li>
        <li>
          &quot;회사&quot;는 &quot;회원&quot;에 대해 회사정책에 따라 등급별로
          구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을
          둘 수 있습니다.
        </li>
      </ol>
      <p className="second_level">제 5 조 (회원정보의 변경)</p>
      <ol className="terms">
        <li>
          &quot;회원&quot;은 프로필 정보 수정 화면을 통하여 언제든지 본인의
          개인정보를 열람하고 수정할 수 있습니다. 다만, &quot;서비스&quot;
          관리를 위해 필요한 생년월일, 성별은 수정이 불가능합니다.
        </li>
        <li>
          &quot;회원&quot;은 생년월일, 성별을 오기재한 경우 고객센터 문의를 통해
          수정할 수 있습니다.
        </li>
        <li>
          &quot;회원&quot;은 회원가입신청 시 기재한 사항이 변경되었을 경우
          온라인으로 수정을 하거나 전자우편 기타 방법으로 &quot;회사&quot;에
          대하여 그 변경사항을 알려야 합니다.
        </li>
        <li>
          제2항의 변경사항을 &quot;회사&quot;에 알리지 않아 발생한 불이익에
          대하여 &quot;회사&quot;는 책임지지 않습니다.
        </li>
        <li>
          &quot;회원&quot;은 언제든지 고객센터 또는 회원 정보 수정 메뉴 등을
          통하여 이용계약 해지 신청(회원탈퇴)을 할 수 있습니다.
          &quot;회원&quot;이 회원탈퇴를 신청한 경우 회사는 회원 본인 여부를
          확인할 수 있으며, 관계법규 등이 정하는 바에 따라 이를 즉시 처리합니다.
          단, &quot;회사&quot;는 서비스 운영 원칙에 따라 30일 동안 재가입을
          제한할 수 있습니다.
        </li>
        <li>
          &quot;회원&quot;이 계약을 해지할 경우, 관련법 및 개인정보처리방침에
          따라 &quot;회사&quot;가 회원정보를 보유하는 경우를 제외하고는 해지
          즉시 &quot;회원&quot;의 모든 데이터는 소멸됩니다.
        </li>
        <li>
          &quot;회원&quot;이 연속하여 일 년 동안 서비스에 log-in한 기록이 없는
          경우 회원 자격을 상실할 수 있습니다.
        </li>
      </ol>
      <p className="second_level">제 6 조 (개인정보보호 의무)</p>
      &quot;회사&quot;는 &quot;개인정보 보호법&quot; 등 관계 법령이 정하는 바에
      따라 &quot;회원&quot;의 개인정보를 보호하기 위해 노력합니다. 개인정보의
      보호 및 사용에 대해서는 관련법 및 &quot;회사&quot;의 개인정보처리방침이
      적용됩니다. 다만, &quot;회사&quot;의 공식 사이트 이외의 링크된
      사이트에서는 &quot;회사&quot;의 개인정보처리방침이 적용되지 않습니다.
      <br />
      &quot;회원&quot;이 작성한 &quot;게시물&quot; 중 설문응답 결과가 포함된
      정보는 &quot;회원&quot;이 동의한 바와 같이 &quot;회원&quot;이 가입 탈퇴를
      한 때로부터 5년간 &quot;회사&quot;의 통계분석 등의 목적과 이익에 따라
      이용할 수 있고, 탈퇴 후 5년이 경과한 후에는 해당 정보를 관련 법령에 따라
      가명처리 또는 익명처리하여 &quot;회사&quot;의 목적과 이익에 따라
      이용합니다.
      <p className="second_level">
        제 7 조 (&quot;회원&quot;의 &quot;계정&quot; 관리에 대한 의무)
      </p>
      <ol className="terms">
        <li>
          &quot;회원&quot;은 &quot;계정&quot;에 관한 관리책임은
          &quot;회원&quot;에게 있으며, 이를 제3자가 이용하도록 하여서는
          안됩니다.
        </li>
        <li>
          &quot;회원&quot;은 &quot;계정&quot;이 도용되거나 제3자가 사용하고
          있음을 인지한 경우에는 이를 즉시 &quot;회사&quot;에 통지하고
          &quot;회사&quot;의 안내에 따라야 합니다.
        </li>
        <li>
          제6항의 경우에 해당 &quot;회원&quot;이 &quot;회사&quot;에 그 사실을
          통지하지 않거나, 통지한 경우에도 &quot;회사&quot;의 안내에 따르지 않아
          발생한 불이익에 대하여 &quot;회사&quot;는 책임지지 않습니다.
        </li>
      </ol>
      <p className="second_level">제 8 조 (&quot;회사&quot;의 의무)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;는 법령과 이 약관이 금지하거나 공서양속에 반하는
          행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로
          재화, 용역을 제공하는 데 최선을 다하여야 합니다.
        </li>
        <li>
          &quot;회사&quot;는 이용자가 안전하게 &quot;서비스&quot;를 이용할 수
          있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을
          갖추어 이용자의 개인정보보호에 최선을 다하여야 합니다.
        </li>
        <li>
          &quot;회사&quot;는 판매하는 상품이나 용역에 대하여
          &quot;표시ᆞ광고의공정화에관한법률&quot; 제3조의 규정에 위반하는 표시
          및 광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을
          부담합니다.
        </li>
        <li>
          &quot;회사&quot;는 수신거절의 의사를 명확히 표시한 이용자에 대해서는
          영리목적의 광고성 문자메시지 및 전자우편을 발송하지 않습니다.
        </li>
      </ol>
      <p className="second_level">제 9 조 (&quot;회원&quot;의 의무)</p>
      <ol className="terms">
        <li>
          &quot;회원&quot;은 다음 행위를 하여서는 안 됩니다.
          <ol className="sub">
            <li>개인정보의 등록(변경의 경우 포함함)시 허위내용을 등록</li>
            <li>의도적으로 동시에 두 개 이상의 계정을 사용</li>
            <li>&quot;오베이머니&quot;를 부정한 방법으로 적립하거나 사용</li>
            <li>&quot;회사&quot;에 게시된 정보를 임의로 변경</li>
            <li>
              &quot;회사&quot;가 허락하지 않은 정보(컴퓨터 프로그램 등)의 송신
              또는 게시
            </li>
            <li>
              &quot;회사&quot; 기타 제3자의 저작권 등 지적재산권에 대한 침해
            </li>
            <li>
              &quot;회사&quot; 기타 제3자의 명예를 손상시키거나 업무를 방해하는
              행위
            </li>
            <li>
              외설 또는 폭력적인 메시지, 화상, 음성 기타 공서양속에 반하는
              정보를 화면에 공개 또는 게시하는 행위
            </li>
            <li>
              불특정 다수의 자를 대상으로 &quot;회사&quot;의
              &quot;서비스&quot;를 이용하여 영리목적으로 활동하는 행위
            </li>
            <li>
              사실관계를 왜곡하는 정보제공 행위, 기타 &quot;회사&quot;가
              부적절하다고 판단하는 행위
            </li>
            <li>타인의 정보 도용</li>
          </ol>
        </li>
        <li>
          &quot;회원&quot;은 관계법, 이 약관의 규정, 이용안내 및
          &quot;서비스&quot;와 관련하여 공지한 주의사항, &quot;회사&quot;가
          통지하는 사항 등을 준수하여야 하며, 기타 &quot;회사&quot;의 업무에
          방해되는 행위를 하여서는 안 됩니다. 만약 이를 위반할 경우 회원자격이
          박탈될 수 있습니다.
        </li>
      </ol>
      <p className="first_level">제 3 장 서비스의 내용과 이용</p>
      <p className="second_level">제 10 조 (&quot;서비스&quot;의 내용)</p>
      &quot;회사&quot;는 &quot;회원&quot;에게 아래와 같은 서비스를 제공합니다.
      <ol className="terms">
        <li>
          &quot;회사&quot;는 &quot;회원&quot;에게 아래와 같은 서비스를
          제공합니다.
          <ol className="sub">
            <li>
              설문조사에 대해 응답할 수 있는 자격과 이에 따른
              &quot;오베이머니&quot; 제공
            </li>
            <li>
              &quot;오베이머니&quot;를 현금으로 출금 또는
              &quot;제휴컨텐츠&quot;로 교환
            </li>
            <li>이외 제공하는 모든 부가 서비스</li>
          </ol>
        </li>
      </ol>
      <p className="second_level">제 11 조 (&quot;서비스&quot;의 이용)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;는 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의
          경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을
          변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및
          제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시
          공지합니다. 단, &quot;회사&quot;가 합리적으로 예측할 수 없는 불가피한
          여건이나 사정이 있는 경우, 위 공지기간을 단축할 수 있습니다.
        </li>
        <li>
          &quot;회사&quot;가 제공하기로 이용자와 계약을 체결한
          &quot;서비스&quot;의 내용을 재화 등의 품절 또는 기술적 사양의 변경
          등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로
          즉시 통지합니다.
        </li>
        <li>
          전항의 경우 &quot;회사&quot;는 이로 인하여 이용자가 입은 손해를
          배상합니다. 다만, &quot;회사&quot;가 고의 또는 과실이 없음을 입증하는
          경우에는 아무런 책임을 부담하지 않습니다. &quot;회사&quot;는 컴퓨터 등
          정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한
          이유가 있는 경우 &quot;서비스&quot;의 제공을 일시적으로 중단할 수
          있습니다. 다만, &quot;회사&quot;가 사전에 통지할 수 없는 부득이한
          사유가 있는 경우 사후에 통지할 수 있습니다.
        </li>
        <li>
          &quot;회사&quot;는 서비스의 제공에 필요한 경우 정기점검을 실시할 수
          있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.
        </li>
      </ol>
      <p className="second_level">제 12 조 (&quot;서비스&quot;의 변경)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에
          따라 제공하고 있는 전부 또는 일부 &quot;서비스&quot;를 변경할 수
          있습니다.
        </li>
        <li>
          &quot;서비스&quot;의 내용, 이용방법, 이용시간에 대하여 변경이 있는
          경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에
          미리 내용을 초기화면에 게시하여야 합니다.
        </li>
        <li>
          &quot;회사&quot;는 무료로 제공되는 &quot;서비스&quot;의 일부 또는
          전부를 &quot;회사&quot;의 정책 및 운영의 필요상 수정, 중단, 변경할 수
          있으며, 이에 대하여 관련법에 특별한 규정이 없는 한
          &quot;회원&quot;에게 별도의 보상을 하지 않습니다.
        </li>
      </ol>
      <p className="second_level">제 13 조 (정보의 제공 및 광고의 게재)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;는 &quot;회원&quot;이 &quot;서비스&quot; 이용 중
          필요하다고 인정되는 다양한 정보를 공지사항, 전자우편 등의 방법으로
          &quot;회원&quot;에게 제공할 수 있습니다. 다만, &quot;회원&quot;은
          관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는
          언제든지 전자우편 등에 대해서 수신 거절을 할 수 있습니다.
        </li>
        <li>
          제1항의 정보를 전화 및 팩스전송기기에 의하여 전송하려고 하는 경우에는
          &quot;회원&quot;의 사전 동의를 받아서 전송합니다. 다만,
          &quot;회원&quot;의 거래관련 정보 및 고객문의 등에 대한 회신에 있어서는
          제외됩니다.
        </li>
        <li>
          &quot;회사&quot;는 &quot;서비스&quot;의 운영과 관련하여
          &quot;서비스&quot; 화면, 홈페이지, 전자우편 등에 광고를 게재할 수
          있습니다. 광고가 게재된 전자우편 등을 수신한 &quot;회원&quot;은
          수신거절을 &quot;회사&quot;에게 할 수 있습니다.
        </li>
        <li>
          &quot;회원&quot;의 정보는 &quot;의뢰자&quot;에게 아래와 같은 기준에
          의해 제공됩니다.
          <ol className="sub">
            <li>
              &quot;회사&quot;는 &quot;회원&quot;의 설문응답 결과와
              &quot;회원&quot;이 설문조사에 응할 당시의 일부 정보(성별, 나이,
              직업, 지역에 한하며, 이름이나 연락처 등의 정보는 포함되지 않음)을
              &quot;의뢰자&quot;에게 제공하고, 해당 정보는 설문조사의 결과에
              대한 통계 조사 자료로만 활용됩니다. &quot;회사&quot;는 이와 같은
              &quot;회원&quot; 정보를 제공함에 있어서 &quot;회원&quot; 으로부터
              개인정보 보호법에서 정하는 개인정보 제공 동의를 받는 등 관련
              법령에서 정하는 절차를 준수합니다.
            </li>
            <li>
              &quot;회원&quot;에 대하여 &quot;의뢰자&quot;가 인터뷰 또는 개별
              연락을 원할 경우, &quot;회원&quot;과 &quot;회사&quot;의 동의 하에
              모든 과정이 진행됩니다.
            </li>
            <li>
              &quot;의뢰자&quot;는 &quot;회사&quot;로부터 &quot;회원&quot;의
              개인정보를 제공받지 않고 직접 &quot;회원&quot;으로부터 동의를 받아
              &quot;회원&quot;의 개인정보를 수집하여 이용할 수 있으며, 이러한
              처리는 &quot;회사&quot;와 무관하며 해당 &quot;회원&quot;의
              개인정보 처리에 관한 법령 등 준수의 책임은 &quot;의뢰자&quot;에게
              있습니다.
            </li>
          </ol>
        </li>
      </ol>
      <p className="second_level">
        제 14 조 (&quot;회원&quot;의 &quot;오베이머니&quot; 출금 관련 정책)
      </p>
      <ol className="terms">
        <li>
          &quot;오베이머니&quot;를 10,000원 이상 보유한 &quot;회원&quot;은
          보유한 &quot;오베이머니&quot;의 현금출금을 신청 할 수 있습니다.
          &quot;회사&quot;가 정한 소정절차에 따라 &quot;오베이머니&quot;를
          현금으로 환산한 금액을 지급 받을 수 있으며, 이 경우 &quot;회사&quot;는
          &quot;회원&quot;이 지정하는 은행계좌에 상기 금액을 입금함으로써
          적법하게 지급한 것으로 봅니다.
        </li>
        <li>
          &quot;오베이머니&quot;의 사용 및 현금출금과 관련하여
          &quot;오베이머니&quot;는 일 오베이머니(1 오베이머니)당 일 원(1 원)으로
          환산함을 원칙으로 합니다.
        </li>
        <li>
          &quot;오베이머니&quot;의 현금출금에 따라 발생하는 제세공과금은
          &quot;회원&quot;이 부담합니다.
        </li>
        <li>
          &quot;회사&quot;의 &quot;제휴컨텐츠&quot;를 &quot;오베이머니&quot;로
          구매 시 해당 바코드가 전송됨으로써 적법하게 지급한 것으로 봅니다.
        </li>
        <li>
          &quot;회원&quot;은 &quot;오베이머니&quot;를 타인에게 양도하거나 대여
          또는 담보의 목적으로 이용할 수 없습니다. 다만, &quot;회사&quot;가
          인정하는 적법한 절차를 따른 경우는 예외로 합니다.
        </li>
        <li>
          &quot;오베이머니&quot;를 사용할 경우, 먼저 적립 된
          &quot;오베이머니&quot;가 먼저 사용됩니다.
        </li>
        <li>
          &quot;회원&quot;에게 지급된 &quot;오베이머니&quot;의 유효기간은
          3년으로 하며, 3년의 유효기간이 지난 &quot;오베이머니&quot;는
          자동적으로 소멸됩니다. 다만 &quot;오베이머니&quot;의 자동소멸은 2012년
          5월 11일 이후 발생한 &quot;오베이머니&quot;에 한해 적용됩니다.
        </li>
      </ol>
      <p className="second_level">
        제 15 조 (&quot;서비스&quot; 권리의 귀속 및 &quot;게시물&quot;의 이용)
      </p>
      <ol className="terms">
        <li>
          &quot;회원&quot;의 &quot;게시물&quot;이 &quot;정보통신망법&quot; 및
          &quot;저작권법&quot;등 관련법에 위반되는 내용을 포함하는 경우,
          권리자는 관련법이 정한 절차에 따라 해당 &quot;게시물&quot;의 게시중단
          및 삭제 등을 요청할 수 있으며, &quot;회사&quot;는 관련법에 따라 조치를
          취하여야 합니다.
        </li>
        <li>
          &quot;회사&quot;는 전항에 따른 권리자의 요청이 없는 경우라도
          권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에
          위반되는 경우에는 관련법에 따라 해당 &quot;게시물&quot;에 대해
          임시조치 등을 취할 수 있습니다.
        </li>
        <li>
          설문조사 결과를 비롯하여 &quot;서비스&quot; 및 “서비스”로부터 발생한
          결과물에 대한 소유권 및 지적재산권(저작권 포함)은 &quot;회사&quot;에
          귀속됩니다. 단, 제휴계약에 따라 제공된 저작물 등은 제외합니다.
        </li>
        <li>
          &quot;회사&quot;는 서비스와 관련하여 &quot;회원&quot;에게
          &quot;회사&quot;가 정한 이용조건에 따라 &quot;계정&quot;,
          &quot;콘텐츠&quot;, &quot;오베이머니&quot; 등을 이용할 수 있는
          이용권만을 부여하며, &quot;회원&quot;은 이를 양도, 판매, 담보제공 등의
          처분행위를 할 수 없습니다.
        </li>
      </ol>
      <p className="second_level">제 16 조 (&quot;애플리케이션&quot;의 이용)</p>
      <ol className="terms">
        <li>
          &quot;회원&quot;은 모바일 단말기 제조사 및 이동통신사에서 제공하는
          앱스토어를 통해 &quot;애플리케이션&quot;을 설치하여
          &quot;서비스&quot;를 이용할 수 있습니다.
        </li>
        <li>
          &quot;회원&quot;이 &quot;서비스&quot;의 일부 또는 이용규칙을 적용하는
          데에 사용되는 보안 기술이나 소프트웨어를 회피 또는 변경하려 시도를
          하거나 부정 사용 및 타인이 그렇게 하는 것을 조장하는 행위 등은
          금지되어 있으며, 만약 &quot;회원&quot;이 그와 같은 행위를 하는 경우
          이에 대한 모든 책임은 &quot;회원&quot; 본인에게 있습니다.
        </li>
      </ol>
      <p className="first_level">제 4 장 기타</p>
      <p className="second_level">제 17 조 (책임제한)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;는 천재지변 또는 이에 준하는 불가항력으로 인하여
          &quot;서비스&quot;를 제공할 수 없는 경우에는 &quot;서비스&quot; 제공에
          관한 책임이 면제됩니다.
        </li>
        <li>
          &quot;회사&quot;는 &quot;회원&quot;의 귀책사유로 인한
          &quot;서비스&quot; 이용의 장애에 대하여는 책임을 지지 않습니다.
        </li>
        <li>
          &quot;회사&quot;는 무료로 제공되는 &quot;서비스&quot; 이용과 관련하여
          관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.
        </li>
      </ol>
      <p className="second_level">제 18 조 (준거법 및 재판관할)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;와 &quot;회원&quot; 간 제기된 소송은 대한민국법을
          준거법으로 합니다.
        </li>
        <li>
          &quot;회사&quot;와 &quot;회원&quot;간 발생한 분쟁에 관한 소송은
          민사소송법 상의 관할법원에 제소합니다.
        </li>
      </ol>
      <p className="second_level">제 19 조 (사업자 정보)</p>
      <ol className="terms">
        <li>회사명: 주식회사 오픈서베이</li>
        <li>대표자: 황희영</li>
        <li>개인정보관리 책임자: 이건노</li>
        <li>주소: 서울특별시 강남구 강남대로84길 13 KR타워 12층</li>
        <li>대표전화: 02-2070-2110</li>
      </ol>
      <br />
      <p>- 공고일자: 2022년 10월 24일</p>
      <p className="second_level">[부칙]</p>
      <ol className="terms">
        <li>이 약관은 2022년 10월 31일부터 시행합니다.</li>
      </ol>
    </div>
  ),
  '2022.06.29~2022.10.30': (
    <div className="terms-body">
      <p className="first_level">제 1 장 총칙</p>
      <p className="second_level">제 1 조 (목적)</p>이 약관은 주식회사
      오픈서베이(이하 &quot;회사&quot;)가 제공하는 OVEY 및 OPENSURVEY
      서비스(이하 &quot;서비스&quot;)의 이용과 관련하여 회사와 회원과의 권리,
      의무 및 책임사항을 규정함을 목적으로 합니다.
      <p className="second_level">제 2 조 (정의)</p>이 약관에서 사용하는 용어의
      정의는 다음과 같습니다.
      <ol className="terms">
        <li>
          &quot;서비스&quot;라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의
          각종 유무선 장치를 포함)와 상관없이 &quot;회원&quot;이 이용할 수 있는
          오베이 및 오베이 관련 제반 서비스를 의미합니다.
        </li>
        <li>
          &quot;회원&quot;이라 함은 회사의 &quot;서비스&quot;에 접속하여 이
          약관에 따라 &quot;회사&quot;와 이용계약을 체결하고 &quot;회사&quot;가
          제공하는 &quot;서비스&quot;를 이용하는 이용자를 말합니다.
        </li>
        <li>
          &quot;비회원&quot;이라 함은 회원에 가입하지 않고 &quot;회사&quot;가
          제공하는 &quot;서비스&quot;를 이용하는 자를 말합니다.
        </li>
        <li>
          &quot;애플리케이션&quot;이라 함은 iOS, Android 등의 모바일 운영체제에
          탑재되어 있는 앱스토어를 통해 단말기에 설치하여 이용할 수 있도록
          구성된 프로그램을 말합니다.
        </li>
        <li>
          &quot;의뢰자&quot;는 &quot;회원&quot;이 &quot;서비스&quot;를 통해
          참여하는 조사의 의뢰 주체를 의미합니다.
        </li>
        <li>
          &quot;계정&quot;이라 함은 &quot;회원&quot;의 식별과
          &quot;서비스&quot;이용을 위하여 &quot;회원&quot;이 인증하고
          &quot;회사&quot;가 승인하는 휴대전화번호를 의미합니다.
        </li>
        <li>
          &quot;게시물&quot;이라 함은 &quot;회원&quot;이 &quot;서비스&quot;를
          이용함에 있어 &quot;회원&quot;이 &quot;서비스&quot;에 게시한 문자,
          문서, 그림, 음성, 링크, 파일 혹은 이들의 조합으로 이루어진 정보 등
          모든 정보나 자료를 의미합니다.
        </li>
        <li>
          &quot;오베이머니&quot;라 함은 &quot;회원&quot;이 &quot;서비스&quot;에
          종속된 활동을 통해 부여받은 것으로, 현금으로 출금 또는
          &quot;제휴컨텐츠&quot; 이용에 사용할 수 있는 가상의 화폐를 의미합니다.
        </li>
        <li>
          &quot;제휴컨텐츠&quot;라 함은 &quot;회사&quot;가 제휴를 통하여
          &quot;회원&quot;이 &quot;오베이머니&quot;를 지급하고 사용할 수 있는
          다양한 서비스를 의미합니다.
        </li>
      </ol>
      <p className="second_level">제 3 조 (약관의 명시와 개정)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;는 이 약관의 내용을 이용자가 쉽게 알 수 있도록 서비스
          초기 화면(전면)에 게시합니다.
        </li>
        <li>
          &quot;회사&quot;는 &quot;약관의 규제에 관한 법률&quot;,
          &quot;정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하
          &quot;정보통신망법&quot;)&quot; 등 관련법을 위배하지 않는 범위에서 이
          약관을 개정할 수 있습니다.
        </li>
        <li>
          &quot;회사&quot;는 약관을 개정할 경우, 적용일자 및 개정사유를 명시하여
          현행약관과 함께 사이트의 초기화면에 그 적용일 7일 이전부터 적용일자
          전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는
          경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우
          &quot;회사&quot;는 개정 전 내용과 개정 후 내용을 명확하게 비교하여
          이용자가 알기 쉽도록 표시합니다.
        </li>
        <li>
          &quot;회사&quot;가 개정약관을 공지 또는 통지하면서
          &quot;회원&quot;에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가
          표명된 것으로 본다는 뜻을 명확하게 공지 또는 고지하였음에도
          &quot;회원&quot;이 명시적으로 거부의 의사표시를 하지 아니한 경우,
          &quot;회원&quot;이 개정약관에 동의한 것으로 봅니다.
        </li>
        <li>
          &quot;회원&quot;이 개정약관의 적용에 동의하지 않는 경우
          &quot;회사&quot;는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은
          이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한
          사정이 있는 경우에는 &quot;회사&quot;는 이용계약을 해지할 수 있습니다.
        </li>
        <li>
          본 약관에서 명시하지 않은 사항과 본 약관의 해석에 관하여는 관계법령
          또는 상관례에 따릅니다.
        </li>
      </ol>
      <p className="first_level">제 2 장 서비스 계약의 체결</p>
      <p className="second_level">제 4 조 (서비스 이용계약 체결)</p>
      <ol className="terms">
        <li>
          &quot;서비스&quot; 이용계약은 아래의 방법으로 체결이 가능합니다.
          <ol className="sub">
            <li>
              &quot;회원&quot;이 되고자 하는 자(이하 &quot;가입신청자&quot;)가
              이용약관에 동의를 하고 &quot;회사&quot;에서 요구하는 정보를 입력한
              경우 &quot;회사&quot;가 이러한 신청에 대하여 승낙함으로써
              체결됩니다.
            </li>
          </ol>
        </li>
        <li>
          &quot;회사&quot;는 다음 각 호에 해당하는 신청에 대하여 승낙을 하지
          않거나, 사후에 통보 없이 이용계약을 해지할 수 있습니다.
          <ol className="sub">
            <li>
              가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는
              경우 (다만, 회원자격 상실 후 1년이 경과한 자로서
              &quot;회사&quot;의 회원 재가입 승낙을 얻은 경우에는 예외로 함)
              <li>본인 계정이 아닌 타인 계정을 이용한 경우</li>
              <li>
                &quot;오베이머니&quot;를 부정한 방법으로 적립하거나 사용한 경우
              </li>
              <li>
                허위의 정보를 기재하거나, &quot;서비스&quot;에서 제시하는 내용을
                기재하지 않은 경우
              </li>
              <li>가입신청자가 만 14세 미만 아동인 경우</li>
              <li>
                이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반
                사항을 위반하며 신청하는 경우
              </li>
            </li>
          </ol>
        </li>
        <li>
          제1항에 따른 신청에 있어 &quot;회사&quot;는 &quot;회원&quot;의 종류에
          따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
        </li>
        <li>
          실제 휴대전화의 소유주임을 확인하기 위해서 회원가입 당시 휴대전화번호
          인증절차를 거치게 됩니다.
        </li>
        <li>
          다른 휴대전화에서 &quot;서비스&quot; 사용을 연속하기 위해서 회원가입
          당시 휴대전화번호 인증절차를 거치게 됩니다.
        </li>
        <li>
          다음의 경우에는 &quot;계정&quot; 생성이 승인되지 않을 수 있습니다.
          <ol className="sub">
            <li>
              다른 사람의 명의나 휴대전화번호 등 개인정보를 이용하여
              &quot;계정&quot;을 생성하는 행위
            </li>
            <li>동일인이 다수의 &quot;계정&quot;을 생성하는 행위</li>
            <li>
              &quot;계정&quot; 생성 시 필요한 정보를 입력하지 않거나 허위 정보를
              입력하는 행위
            </li>
            <li>
              과거에 &quot;서비스&quot;의 운영원칙 또는 법률 위반 등의 정당한
              사유로 해당 &quot;계정&quot;이 삭제 또는 징계된 경우
            </li>
          </ol>
        </li>
        <li>
          &quot;회사&quot;는 &quot;서비스&quot; 관련설비의 여유가 없거나, 기술상
          또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
        </li>
        <li>
          제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한
          경우, &quot;회사&quot;는 원칙적으로 이를 가입신청자에게 알리도록
          합니다.
        </li>
        <li>
          이용계약의 성립 시기는 &quot;회사&quot;가 가입완료를 신청절차 상에서
          표시한 시점으로 합니다.
        </li>
        <li>
          &quot;회사&quot;는 &quot;회원&quot;에 대해 회사정책에 따라 등급별로
          구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을
          둘 수 있습니다.
        </li>
      </ol>
      <p className="second_level">제 5 조 (회원정보의 변경)</p>
      <ol className="terms">
        <li>
          &quot;회원&quot;은 프로필 정보 수정 화면을 통하여 언제든지 본인의
          개인정보를 열람하고 수정할 수 있습니다. 다만, &quot;서비스&quot;
          관리를 위해 필요한 생년월일, 성별은 수정이 불가능합니다.
        </li>
        <li>
          &quot;회원&quot;은 생년월일, 성별을 오기재한 경우 고객센터 문의를 통해
          수정할 수 있습니다.
        </li>
        <li>
          &quot;회원&quot;은 회원가입신청 시 기재한 사항이 변경되었을 경우
          온라인으로 수정을 하거나 전자우편 기타 방법으로 &quot;회사&quot;에
          대하여 그 변경사항을 알려야 합니다.
        </li>
        <li>
          제2항의 변경사항을 &quot;회사&quot;에 알리지 않아 발생한 불이익에
          대하여 &quot;회사&quot;는 책임지지 않습니다.
        </li>
        <li>
          &quot;회원&quot;은 언제든지 고객센터 또는 회원 정보 수정 메뉴 등을
          통하여 이용계약 해지 신청(회원탈퇴)을 할 수 있습니다.
          &quot;회원&quot;이 회원탈퇴를 신청한 경우 회사는 회원 본인 여부를
          확인할 수 있으며, 관계법규 등이 정하는 바에 따라 이를 즉시 처리합니다.
          단, &quot;회사&quot;는 서비스 운영 원칙에 따라 30일 동안 재가입을
          제한할 수 있습니다.
        </li>
        <li>
          &quot;회원&quot;이 계약을 해지할 경우, 관련법 및 개인정보처리방침에
          따라 &quot;회사&quot;가 회원정보를 보유하는 경우를 제외하고는 해지
          즉시 &quot;회원&quot;의 모든 데이터는 소멸됩니다.
        </li>
        <li>
          &quot;회원&quot;이 연속하여 일 년 동안 서비스에 log-in한 기록이 없는
          경우 회원 자격을 상실할 수 있습니다.
        </li>
      </ol>
      <p className="second_level">제 6 조 (개인정보보호 의무)</p>
      &quot;회사&quot;는 &quot;개인정보 보호법&quot; 등 관계 법령이 정하는 바에
      따라 &quot;회원&quot;의 개인정보를 보호하기 위해 노력합니다. 개인정보의
      보호 및 사용에 대해서는 관련법 및 &quot;회사&quot;의 개인정보처리방침이
      적용됩니다. 다만, &quot;회사&quot;의 공식 사이트 이외의 링크된
      사이트에서는 &quot;회사&quot;의 개인정보처리방침이 적용되지 않습니다.
      <br />
      &quot;회원&quot;이 작성한 &quot;게시물&quot; 중 설문응답 결과가 포함된
      정보는 &quot;회원&quot;이 동의한 바와 같이 &quot;회원&quot;이 가입 탈퇴를
      한 때로부터 5년간 &quot;회사&quot;의 통계분석 등의 목적과 이익에 따라
      이용할 수 있고, 탈퇴 후 5년이 경과한 후에는 해당 정보를 관련 법령에 따라
      가명처리 또는 익명처리하여 &quot;회사&quot;의 목적과 이익에 따라
      이용합니다.
      <p className="second_level">
        제 7 조 (&quot;회원&quot;의 &quot;계정&quot; 관리에 대한 의무)
      </p>
      <ol className="terms">
        <li>
          &quot;회원&quot;은 &quot;계정&quot;에 관한 관리책임은
          &quot;회원&quot;에게 있으며, 이를 제3자가 이용하도록 하여서는
          안됩니다.
        </li>
        <li>
          &quot;회원&quot;은 &quot;계정&quot;이 도용되거나 제3자가 사용하고
          있음을 인지한 경우에는 이를 즉시 &quot;회사&quot;에 통지하고
          &quot;회사&quot;의 안내에 따라야 합니다.
        </li>
        <li>
          제6항의 경우에 해당 &quot;회원&quot;이 &quot;회사&quot;에 그 사실을
          통지하지 않거나, 통지한 경우에도 &quot;회사&quot;의 안내에 따르지 않아
          발생한 불이익에 대하여 &quot;회사&quot;는 책임지지 않습니다.
        </li>
      </ol>
      <p className="second_level">제 8 조 (&quot;회사&quot;의 의무)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;는 법령과 이 약관이 금지하거나 공서양속에 반하는
          행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로
          재화, 용역을 제공하는 데 최선을 다하여야 합니다.
        </li>
        <li>
          &quot;회사&quot;는 이용자가 안전하게 &quot;서비스&quot;를 이용할 수
          있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을
          갖추어 이용자의 개인정보보호에 최선을 다하여야 합니다.
        </li>
        <li>
          &quot;회사&quot;는 판매하는 상품이나 용역에 대하여
          &quot;표시ᆞ광고의공정화에관한법률&quot; 제3조의 규정에 위반하는 표시
          및 광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을
          부담합니다.
        </li>
        <li>
          &quot;회사&quot;는 수신거절의 의사를 명확히 표시한 이용자에 대해서는
          영리목적의 광고성 문자메시지 및 전자우편을 발송하지 않습니다.
        </li>
      </ol>
      <p className="second_level">제 9 조 (&quot;회원&quot;의 의무)</p>
      <ol className="terms">
        <li>
          &quot;회원&quot;은 다음 행위를 하여서는 안 됩니다.
          <ol className="sub">
            <li>개인정보의 등록(변경의 경우 포함함)시 허위내용을 등록</li>
            <li>의도적으로 동시에 두 개 이상의 계정을 사용</li>
            <li>&quot;오베이머니&quot;를 부정한 방법으로 적립하거나 사용</li>
            <li>&quot;회사&quot;에 게시된 정보를 임의로 변경</li>
            <li>
              &quot;회사&quot;가 허락하지 않은 정보(컴퓨터 프로그램 등)의 송신
              또는 게시
            </li>
            <li>
              &quot;회사&quot; 기타 제3자의 저작권 등 지적재산권에 대한 침해
            </li>
            <li>
              &quot;회사&quot; 기타 제3자의 명예를 손상시키거나 업무를 방해하는
              행위
            </li>
            <li>
              외설 또는 폭력적인 메시지, 화상, 음성 기타 공서양속에 반하는
              정보를 화면에 공개 또는 게시하는 행위
            </li>
            <li>
              불특정 다수의 자를 대상으로 &quot;회사&quot;의
              &quot;서비스&quot;를 이용하여 영리목적으로 활동하는 행위
            </li>
            <li>
              사실관계를 왜곡하는 정보제공 행위, 기타 &quot;회사&quot;가
              부적절하다고 판단하는 행위
            </li>
            <li>타인의 정보 도용</li>
          </ol>
        </li>
        <li>
          &quot;회원&quot;은 관계법, 이 약관의 규정, 이용안내 및
          &quot;서비스&quot;와 관련하여 공지한 주의사항, &quot;회사&quot;가
          통지하는 사항 등을 준수하여야 하며, 기타 &quot;회사&quot;의 업무에
          방해되는 행위를 하여서는 안 됩니다. 만약 이를 위반할 경우 회원자격이
          박탈될 수 있습니다.
        </li>
      </ol>
      <p className="first_level">제 3 장 서비스의 내용과 이용</p>
      <p className="second_level">제 10 조 (&quot;서비스&quot;의 내용)</p>
      &quot;회사&quot;는 &quot;회원&quot;에게 아래와 같은 서비스를 제공합니다.
      <ol className="terms">
        <li>
          &quot;회사&quot;는 &quot;회원&quot;에게 아래와 같은 서비스를
          제공합니다.
          <ol className="sub">
            <li>
              설문조사에 대해 응답할 수 있는 자격과 이에 따른
              &quot;오베이머니&quot; 제공
            </li>
            <li>
              &quot;오베이머니&quot;를 현금으로 출금 또는
              &quot;제휴컨텐츠&quot;로 교환
            </li>
            <li>이외 제공하는 모든 부가 서비스</li>
          </ol>
        </li>
      </ol>
      <p className="second_level">제 11 조 (&quot;서비스&quot;의 이용)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;는 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의
          경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을
          변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및
          제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시
          공지합니다. 단, &quot;회사&quot;가 합리적으로 예측할 수 없는 불가피한
          여건이나 사정이 있는 경우, 위 공지기간을 단축할 수 있습니다.
        </li>
        <li>
          &quot;회사&quot;가 제공하기로 이용자와 계약을 체결한
          &quot;서비스&quot;의 내용을 재화 등의 품절 또는 기술적 사양의 변경
          등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로
          즉시 통지합니다.
        </li>
        <li>
          전항의 경우 &quot;회사&quot;는 이로 인하여 이용자가 입은 손해를
          배상합니다. 다만, &quot;회사&quot;가 고의 또는 과실이 없음을 입증하는
          경우에는 아무런 책임을 부담하지 않습니다. &quot;회사&quot;는 컴퓨터 등
          정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한
          이유가 있는 경우 &quot;서비스&quot;의 제공을 일시적으로 중단할 수
          있습니다. 다만, &quot;회사&quot;가 사전에 통지할 수 없는 부득이한
          사유가 있는 경우 사후에 통지할 수 있습니다.
        </li>
        <li>
          &quot;회사&quot;는 서비스의 제공에 필요한 경우 정기점검을 실시할 수
          있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.
        </li>
      </ol>
      <p className="second_level">제 12 조 (&quot;서비스&quot;의 변경)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에
          따라 제공하고 있는 전부 또는 일부 &quot;서비스&quot;를 변경할 수
          있습니다.
        </li>
        <li>
          &quot;서비스&quot;의 내용, 이용방법, 이용시간에 대하여 변경이 있는
          경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에
          미리 내용을 초기화면에 게시하여야 합니다.
        </li>
        <li>
          &quot;회사&quot;는 무료로 제공되는 &quot;서비스&quot;의 일부 또는
          전부를 &quot;회사&quot;의 정책 및 운영의 필요상 수정, 중단, 변경할 수
          있으며, 이에 대하여 관련법에 특별한 규정이 없는 한
          &quot;회원&quot;에게 별도의 보상을 하지 않습니다.
        </li>
      </ol>
      <p className="second_level">제 13 조 (정보의 제공 및 광고의 게재)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;는 &quot;회원&quot;이 &quot;서비스&quot; 이용 중
          필요하다고 인정되는 다양한 정보를 공지사항, 전자우편 등의 방법으로
          &quot;회원&quot;에게 제공할 수 있습니다. 다만, &quot;회원&quot;은
          관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는
          언제든지 전자우편 등에 대해서 수신 거절을 할 수 있습니다.
        </li>
        <li>
          제1항의 정보를 전화 및 팩스전송기기에 의하여 전송하려고 하는 경우에는
          &quot;회원&quot;의 사전 동의를 받아서 전송합니다. 다만,
          &quot;회원&quot;의 거래관련 정보 및 고객문의 등에 대한 회신에 있어서는
          제외됩니다.
        </li>
        <li>
          &quot;회사&quot;는 &quot;서비스&quot;의 운영과 관련하여
          &quot;서비스&quot; 화면, 홈페이지, 전자우편 등에 광고를 게재할 수
          있습니다. 광고가 게재된 전자우편 등을 수신한 &quot;회원&quot;은
          수신거절을 &quot;회사&quot;에게 할 수 있습니다.
        </li>
        <li>
          &quot;회원&quot;의 정보는 &quot;의뢰자&quot;에게 아래와 같은 기준에
          의해 제공됩니다.
          <ol className="sub">
            <li>
              &quot;회사&quot;는 &quot;회원&quot;의 설문응답 결과와
              &quot;회원&quot;이 설문조사에 응할 당시의 일부 정보(성별, 나이,
              직업, 지역에 한하며, 이름이나 연락처 등의 정보는 포함되지 않음)을
              &quot;의뢰자&quot;에게 제공하고, 해당 정보는 설문조사의 결과에
              대한 통계 조사 자료로만 활용됩니다. &quot;회사&quot;는 이와 같은
              &quot;회원&quot; 정보를 제공함에 있어서 &quot;회원&quot; 으로부터
              개인정보 보호법에서 정하는 개인정보 제공 동의를 받는 등 관련
              법령에서 정하는 절차를 준수합니다.
            </li>
            <li>
              &quot;회원&quot;에 대하여 &quot;의뢰자&quot;가 인터뷰 또는 개별
              연락을 원할 경우, &quot;회원&quot;과 &quot;회사&quot;의 동의 하에
              모든 과정이 진행됩니다.
            </li>
          </ol>
        </li>
      </ol>
      <p className="second_level">
        제 14 조 (&quot;회원&quot;의 &quot;오베이머니&quot; 출금 관련 정책)
      </p>
      <ol className="terms">
        <li>
          &quot;오베이머니&quot;를 10,000원 이상 보유한 &quot;회원&quot;은
          보유한 &quot;오베이머니&quot;의 현금출금을 신청 할 수 있습니다.
          &quot;회사&quot;가 정한 소정절차에 따라 &quot;오베이머니&quot;를
          현금으로 환산한 금액을 지급 받을 수 있으며, 이 경우 &quot;회사&quot;는
          &quot;회원&quot;이 지정하는 은행계좌에 상기 금액을 입금함으로써
          적법하게 지급한 것으로 봅니다.
        </li>
        <li>
          &quot;오베이머니&quot;의 사용 및 현금출금과 관련하여
          &quot;오베이머니&quot;는 일 오베이머니(1 오베이머니)당 일 원(1 원)으로
          환산함을 원칙으로 합니다.
        </li>
        <li>
          &quot;오베이머니&quot;의 현금출금에 따라 발생하는 제세공과금은
          &quot;회원&quot;이 부담합니다.
        </li>
        <li>
          &quot;회사&quot;의 &quot;제휴컨텐츠&quot;를 &quot;오베이머니&quot;로
          구매 시 해당 바코드가 전송됨으로써 적법하게 지급한 것으로 봅니다.
        </li>
        <li>
          &quot;회원&quot;은 &quot;오베이머니&quot;를 타인에게 양도하거나 대여
          또는 담보의 목적으로 이용할 수 없습니다. 다만, &quot;회사&quot;가
          인정하는 적법한 절차를 따른 경우는 예외로 합니다.
        </li>
        <li>
          &quot;오베이머니&quot;를 사용할 경우, 먼저 적립 된
          &quot;오베이머니&quot;가 먼저 사용됩니다.
        </li>
        <li>
          &quot;회원&quot;에게 지급된 &quot;오베이머니&quot;의 유효기간은
          3년으로 하며, 3년의 유효기간이 지난 &quot;오베이머니&quot;는
          자동적으로 소멸됩니다. 다만 &quot;오베이머니&quot;의 자동소멸은 2012년
          5월 11일 이후 발생한 &quot;오베이머니&quot;에 한해 적용됩니다.
        </li>
      </ol>
      <p className="second_level">
        제 15 조 (&quot;서비스&quot; 권리의 귀속 및 &quot;게시물&quot;의 이용)
      </p>
      <ol className="terms">
        <li>
          &quot;회원&quot;의 &quot;게시물&quot;이 &quot;정보통신망법&quot; 및
          &quot;저작권법&quot;등 관련법에 위반되는 내용을 포함하는 경우,
          권리자는 관련법이 정한 절차에 따라 해당 &quot;게시물&quot;의 게시중단
          및 삭제 등을 요청할 수 있으며, &quot;회사&quot;는 관련법에 따라 조치를
          취하여야 합니다.
        </li>
        <li>
          &quot;회사&quot;는 전항에 따른 권리자의 요청이 없는 경우라도
          권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에
          위반되는 경우에는 관련법에 따라 해당 &quot;게시물&quot;에 대해
          임시조치 등을 취할 수 있습니다.
        </li>
        <li>
          설문조사 결과를 비롯하여 &quot;서비스&quot; 및 “서비스”로부터 발생한
          결과물에 대한 소유권 및 지적재산권(저작권 포함)은 &quot;회사&quot;에
          귀속됩니다. 단, 제휴계약에 따라 제공된 저작물 등은 제외합니다.
        </li>
        <li>
          &quot;회사&quot;는 서비스와 관련하여 &quot;회원&quot;에게
          &quot;회사&quot;가 정한 이용조건에 따라 &quot;계정&quot;,
          &quot;콘텐츠&quot;, &quot;오베이머니&quot; 등을 이용할 수 있는
          이용권만을 부여하며, &quot;회원&quot;은 이를 양도, 판매, 담보제공 등의
          처분행위를 할 수 없습니다.
        </li>
      </ol>
      <p className="second_level">제 16 조 (&quot;애플리케이션&quot;의 이용)</p>
      <ol className="terms">
        <li>
          &quot;회원&quot;은 모바일 단말기 제조사 및 이동통신사에서 제공하는
          앱스토어를 통해 &quot;애플리케이션&quot;을 설치하여
          &quot;서비스&quot;를 이용할 수 있습니다.
        </li>
        <li>
          &quot;회원&quot;이 &quot;서비스&quot;의 일부 또는 이용규칙을 적용하는
          데에 사용되는 보안 기술이나 소프트웨어를 회피 또는 변경하려 시도를
          하거나 부정 사용 및 타인이 그렇게 하는 것을 조장하는 행위 등은
          금지되어 있으며, 만약 &quot;회원&quot;이 그와 같은 행위를 하는 경우
          이에 대한 모든 책임은 &quot;회원&quot; 본인에게 있습니다.
        </li>
      </ol>
      <p className="first_level">제 4 장 기타</p>
      <p className="second_level">제 17 조 (책임제한)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;는 천재지변 또는 이에 준하는 불가항력으로 인하여
          &quot;서비스&quot;를 제공할 수 없는 경우에는 &quot;서비스&quot; 제공에
          관한 책임이 면제됩니다.
        </li>
        <li>
          &quot;회사&quot;는 &quot;회원&quot;의 귀책사유로 인한
          &quot;서비스&quot; 이용의 장애에 대하여는 책임을 지지 않습니다.
        </li>
        <li>
          &quot;회사&quot;는 무료로 제공되는 &quot;서비스&quot; 이용과 관련하여
          관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.
        </li>
      </ol>
      <p className="second_level">제 18 조 (준거법 및 재판관할)</p>
      <ol className="terms">
        <li>
          &quot;회사&quot;와 &quot;회원&quot; 간 제기된 소송은 대한민국법을
          준거법으로 합니다.
        </li>
        <li>
          &quot;회사&quot;와 &quot;회원&quot;간 발생한 분쟁에 관한 소송은
          민사소송법 상의 관할법원에 제소합니다.
        </li>
      </ol>
      <p className="second_level">제 19 조 (사업자 정보)</p>
      <ol className="terms">
        <li>회사명: 주식회사 오픈서베이</li>
        <li>대표자: 황희영</li>
        <li>개인정보관리 책임자: 이건노</li>
        <li>주소: 서울특별시 강남구 강남대로84길 13 KR타워 12층</li>
        <li>대표전화: 02-2070-2110</li>
      </ol>
      <p className="second_level">[부칙]</p>
      <ol className="terms">
        <li>이 약관은 2022년 6월 29일부터 시행합니다.</li>
      </ol>
    </div>
  ),
};
