import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import './App.css';
import Main from 'components/main/Index';
import PrivacyTerms from 'components/terms/PrivacyTerms';
import ServiceTerms from 'components/terms/ServiceTerms';
import PersonalInformationTerms from 'components/terms/PersonalInformationTerms';
import PrivacyTermsApp from 'components/terms/PrivacyTermsApp';
import ProfileAnswerTerms from 'components/terms/ProfileAnswerTerms';
import ThirdPartyPrivacyTerms from 'components/terms/ThirdPartyPrivacyTerms';

const App = (): JSX.Element => {
  return (
    <section className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/privacy-terms" element={<PrivacyTerms />} />
          <Route path="/privacy-terms/:period" element={<PrivacyTerms />} />
          <Route path="/privacy-terms/app" element={<PrivacyTermsApp />} />
          <Route path="/service-terms" element={<ServiceTerms />} />
          <Route path="/service-terms/:period" element={<ServiceTerms />} />
          <Route
            path="/personal-information-terms"
            element={<PersonalInformationTerms />}
          />
          <Route
            path="/profile-answer-terms/app"
            element={<ProfileAnswerTerms />}
          />
          <Route
            path="/profile-answer-terms/app/:period"
            element={<ProfileAnswerTerms />}
          />
          <Route
            path="/third-party-privacy-terms/app"
            element={<ThirdPartyPrivacyTerms />}
          />
        </Routes>
      </Router>
    </section>
  );
};

export default App;
