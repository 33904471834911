import {
  Description,
  Divider,
  PrivacyTermsAppWrapper,
  Title,
} from 'components/terms/Terms.styled';

export const THIRD_PARTY_PRIVACY_TERMS_HISTORY: Record<string, JSX.Element> = {
  '2023.05.23~': (
    <PrivacyTermsAppWrapper>
      <Title>제휴 설문 참여를 위한 개인정보 제3자 제공에 대한 동의</Title>
      <Description>
        설문 참여 기회 확장을 위해 오픈서베이와 데이터 수집/제공 리서치
        회사(이하 ‘제3자’)와 제휴하였습니다. 제3자가 발송하는 설문을 회원님께
        제공하기 위해 (주)오픈서베이(이하 ‘회사’)는 개인정보 제공에 대한 동의를
        받고 있습니다.
      </Description>
      <Divider />
      <ol style={{ listStyle: 'inside auto' }}>
        <li>
          개인정보를 제공받는 자: <b>(주)데이터스프링</b>
        </li>
        <li>
          개인정보 이용 목적: <b>제휴 설문 발송, 설문 결과 분석 및 활용</b>
        </li>
        <li>제공하는 개인정보 항목</li>
        <ol
          style={{
            paddingInlineStart: '30px',
            listStyle: 'inside lower-alpha',
          }}
        >
          <li>
            다음의 정보는 제휴 설문 발송, 설문 결과 분석 및 활용을 위해
            제공합니다.
            <br />: 성별, 연령, 거주 지역, 직업, 혼인 여부
          </li>
          <li>
            다음의 정보는 설문 결과 분석 및 활용을 위해 제공합니다.
            <br />: 설문 응답 관련 정보(객관식, 주관식 응답 포함)
          </li>
        </ol>
        <li>
          개인정보 보유 및 이용 기간:{' '}
          <b>개인정보 제공 동의를 한 기간에 한해 보유 및 이용</b>
        </li>
      </ol>

      <br />
      <br />

      <ol style={{ listStyle: 'inside auto' }}>
        <li>
          개인정보를 제공받는 자: <b>GMO-Research Inc.</b>
        </li>
        <li>
          개인정보 이용 목적: <b>제휴 설문 발송, 설문 결과 분석 및 활용</b>
        </li>
        <li>제공하는 개인정보 항목</li>
        <ol
          style={{
            paddingInlineStart: '30px',
            listStyle: 'inside lower-alpha',
          }}
        >
          <li>
            다음의 정보는 제휴 설문 발송, 설문 결과 분석 및 활용을 위해
            제공합니다.
            <br />: 성별, 연령, 거주 지역
          </li>
          <li>
            다음의 정보는 설문 결과 분석 및 활용을 위해 제공합니다.
            <br />: 설문 응답 관련 정보(객관식, 주관식 응답 포함)
          </li>
        </ol>
        <li>
          개인정보 보유 및 이용 기간:{' '}
          <b>개인정보 제공 동의를 한 기간에 한해 보유 및 이용</b>
        </li>
      </ol>

      <Divider />
      <Description>
        동의를 받는 과정에 있어 회사는 회원의 의사에 반하여 추가적인 정보를
        수집하거나, 동의의 범위를 벗어난 정보를 제3자와 공유하지 않습니다.
        <br />
        회원은 위 개인정보 제3자 제공에 대해 비동의할 권리가 있으며, 동의하지
        않을 경우 회원은 어떠한 불이익도 당하지 않습니다. 단, 제3자가 제공하는
        제휴 설문에 응답할 수 없습니다.
        <br />
        <br />
        회원이 동의를 한 후 제공하던 개인정보에 대한 삭제 요청이 있을 때, 회사는
        제휴 설문 발송을 위한 개인정보(성별, 연령, 거주 지역, 직업, 혼인 여부)를
        삭제하며 제3자는 제휴 설문 발송에 활용하지 않습니다.
        <br />
        <br />
        설문 응답 관련 정보(객관식, 주관식 응답 포함)의 경우 개인정보 보호법 및
        관련 가이드라인에서 정하는 바에 따른 가명처리 또는 익명처리를 거쳐 해당
        정보를 보유합니다.
      </Description>
    </PrivacyTermsAppWrapper>
  ),
};
