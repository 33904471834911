import Logo from 'assets/Ovey_BI_white.svg';
import { Wrapper, Inner } from './GNB.styled';

const GNB = () => {
  return (
    <Wrapper>
      <Inner>
        <a href="/">
          <img src={Logo} width={63} height={34} alt="오베이 로고" />
        </a>
      </Inner>
    </Wrapper>
  );
};

export default GNB;
