import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Wrapper } from './Terms.styled';

interface Props {
  title: string;
  url: string;
  historyData: Record<string, JSX.Element>;
}

const TermsFrame: React.FC<Props> = ({ title, url, historyData }) => {
  const { period } = useParams();
  const [isListOpen, setListOpen] = useState(false);
  const historyKeyList = Object.keys(historyData);
  const version = period ? historyKeyList.indexOf(period) : 0;
  if (version === -1) {
    window.location.href = '/';
  }
  const prevHistoryKeyList = historyKeyList.slice(1);
  const needShowPrevVersion = prevHistoryKeyList.length > 1 && version === 0;

  return (
    <Wrapper>
      <p className="title-label">[{title}]</p>
      {historyKeyList.map((key, index) => (
        <article key={`${key}_content`}>
          {version === index && historyData[key]}
        </article>
      ))}
      {needShowPrevVersion && (
        <article>
          <button
            className={`listToggleButton ${isListOpen ? 'open' : ''}`}
            type="button"
            onClick={() => {
              setListOpen(!isListOpen);
            }}
          >
            이전 {title} 보기
          </button>
          {isListOpen && (
            <ul className="versionList">
              {prevHistoryKeyList.map((prevKey) => (
                <li key={prevKey}>
                  <a className="versionButton" href={`/${url}/${prevKey}`}>
                    오베이 {title} ({prevKey})
                  </a>
                </li>
              ))}
            </ul>
          )}
        </article>
      )}
    </Wrapper>
  );
};

export default TermsFrame;
