import {
  BoxContents,
  Description,
  Divider,
  PrivacyTermsAppWrapper,
  SubTitle,
  Table,
  Tc,
  Td,
  Title,
  Tr,
} from 'components/terms/Terms.styled';

export const PROFILE_ANSWER_HISTORY: Record<string, JSX.Element> = {
  '2023.05.23~': (
    <PrivacyTermsAppWrapper>
      <Title>프로필 설문에 대한 개인정보 수집 및 이용 동의 (선택)</Title>
      <Description>
        주식회사 오픈서베이는 아래와 같이 개인정보를 수집, 이용합니다.
      </Description>
      <Divider />
      <SubTitle>수집 이용 항목</SubTitle>
      <Table>
        <Tr className="thead">
          <Tc>
            <Td>수집 대상</Td>
          </Tc>
          <Tc>
            <Td>수집 항목</Td>
          </Tc>
        </Tr>
        <Tr>
          <Tc>
            <Td>공통</Td>
          </Tc>
          <Tc>
            <Td>개인 소득, 가구 소득</Td>
          </Tc>
        </Tr>
        <Tr>
          <Tc>
            <Td>만 19세 이상</Td>
          </Tc>
          <Tc>
            <Td>운전 여부, 차량 소유, 차량 관리, 차종, 연료, 원산지</Td>
            <Td>결혼 여부, 가구 구성원 수, 가구 구성, 자녀 여부</Td>
          </Tc>
        </Tr>
        <Tr>
          <Tc>
            <Td>직장인 대상</Td>
          </Tc>
          <Tc>
            <Td>산업군, 직장 분류, 직장 규모, 부서, 직급</Td>
          </Tc>
        </Tr>
      </Table>
      <Divider />
      <SubTitle>수집 이용 목적</SubTitle>
      <BoxContents>세분화된 설문서비스 제공 및 설문대상 선정</BoxContents>
      <SubTitle>보유 및 이용 기간</SubTitle>
      <BoxContents>회원 탈퇴 또는 동의 철회 시까지</BoxContents>
      <Divider />
      <Description>
        위 개인정보 수집, 이용에 동의하지 않으실 수 있으나, 동의하지 않을 경우
        일부 설문 대상자에 포함되지 않을 수 있습니다. 그 밖의 사항은
        개인정보처리방침에 따릅니다.
      </Description>
    </PrivacyTermsAppWrapper>
  ),
};
