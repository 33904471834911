import GooglePlayIcon from 'assets/ic_googleplay_web.svg';
import AppleStoreIcon from 'assets/ic_apple_store.svg';
import { GOOGLE_PLAY_LINK, APPLE_STORE_LINK } from '../../constant/url';
import { gtagClickTracking } from '../../utils/gtag';
import { Wrapper, Inner } from './Top.styled';

const Top = () => {
  const agent = window.navigator.userAgent;
  const isMacOS = /Mac OS X/gi.test(agent);

  return (
    <Wrapper>
      <Inner>
        <article>
          <p className="subTitle">모바일 설문의 혜택!</p>
          <p className="title">오베이, 내 의견의 가치</p>
          <article className="linkWrapper">
            <a
              className="linkButton desktop"
              href={GOOGLE_PLAY_LINK}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                gtagClickTracking('앱 다운로드 접속', 'Google Play');
              }}
            >
              <img src={GooglePlayIcon} alt="구글 플레이 아이콘" />
              Google Play
            </a>
            <a
              className="linkButton desktop"
              href={APPLE_STORE_LINK}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                gtagClickTracking('앱 다운로드 접속', 'Apple Store');
              }}
            >
              <img src={AppleStoreIcon} alt="애플 스토어 아이콘" />
              App Store
            </a>
            <a
              className="appDownload mobile"
              href={isMacOS ? APPLE_STORE_LINK : GOOGLE_PLAY_LINK}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                gtagClickTracking(
                  '앱 다운로드 접속',
                  isMacOS ? 'Apple Store' : 'Google Play',
                );
              }}
            >
              앱 다운로드
            </a>
          </article>
        </article>
      </Inner>
    </Wrapper>
  );
};

export default Top;
