import { PERSONAL_INFORMATION_HISTORY } from 'constant/PersonalInformationTerm';
import TermsFrame from './TermsFrame';

const PersonalInformationTerms = () => {
  return (
    <TermsFrame
      title="개인정보 수집 및 이용에 관한 동의(필수)"
      url="personal-information-terms"
      historyData={PERSONAL_INFORMATION_HISTORY}
    />
  );
};

export default PersonalInformationTerms;
