import styled from 'styled-components';
import TopBg from 'assets/top_bg.png';
import TopBgM from 'assets/top_bg_m.png';

export const Wrapper = styled.section`
  overflow: hidden;
`;

export const Inner = styled.article`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-image: url(${TopBg});
  min-width: 1920px;
  height: 768px;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;

  .subTitle {
    font-weight: 400;
    font-size: 32px;
    line-height: 47px;
  }
  .title {
    margin-top: 7px;
    font-weight: 700;
    font-size: 64px;
    line-height: 92px;
    color: #ffffff;
  }
  .linkWrapper {
    margin-top: 73px;
  }
  .linkButton {
    display: inline-flex;
    padding: 6px 30px 8px 14px;
    align-items: center;
    background: rgba(0, 0, 0, 0.32);
    border: 1px solid rgba(255, 255, 255, 0.24);
    backdrop-filter: blur(16px);
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    color: #ececec;
    text-decoration: none;
    margin-right: 15px;
    transition: background-color 0.2s ease-in-out;

    &:last-of-type {
      margin-right: 0;
    }
    &:hover {
      background-color: #000000;
    }

    img {
      margin-right: 13px;
    }
  }

  .appDownload {
    display: inline-flex;
    align-items: center;
    background: #2396ff;
    backdrop-filter: blur(16px);
    border-radius: 10px;
    padding: 8px 20px;
    height: 48px;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
  }

  @media screen and (max-width: 1240px) {
    width: 100%;
    min-width: 510px;
    height: 400px;
    background-image: url(${TopBgM});

    .subTitle {
      font-size: 16px;
      line-height: 24px;
    }
    .title {
      margin-top: 0;
      font-size: 28px;
      line-height: 40px;
    }
    .linkWrapper {
      margin-top: 40px;
    }
  }
`;
