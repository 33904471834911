import { SERVICE_HISTORY } from 'constant/ServiceTerms';
import TermsFrame from './TermsFrame';

const ServiceTerms = () => {
  return (
    <TermsFrame
      title="이용약관"
      url="service-terms"
      historyData={SERVICE_HISTORY}
    />
  );
};

export default ServiceTerms;
