import LockImg from 'assets/img_lock.svg';
import { Wrapper } from './Security.styled';
import { gtagClickTracking } from '../../utils/gtag';

const Security = () => {
  return (
    <Wrapper>
      <img
        className="lockImg"
        src={LockImg}
        width={464}
        height={320}
        alt="잠금 이미지"
      />
      <article>
        <p className="title">소중한 정보를 철저히 보호합니다.</p>
        <p className="description">
          응답한 모든 내용은 개인을 식별할 수 없도록 처리되어
          <br />
          설문을 의뢰한 곳에 전달됩니다.
        </p>
        <a
          className="link"
          href="/privacy-terms"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            gtagClickTracking('링크 접속', 'privacy-terms');
          }}
        >
          개인정보처리방침 보기
        </a>
      </article>
    </Wrapper>
  );
};

export default Security;
