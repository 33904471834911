interface ConstantProps {
  [key: string]: JSX.Element;
}

export const PERSONAL_INFORMATION_HISTORY: ConstantProps = {
  '': (
    <div className="terms-body">
      <br />
      오픈서베이는 회원님께서 문의한 내용을 응대하고자 최소한의 범위내에서
      개인정보를 수집·이용합니다.
      <p className="second_level">
        <span className="terms-bold">수집 및 이용 목적</span> : 고객센터 온라인
        상담(1:1 문의 신청)
        <br />
        <span className="terms-bold">수집 및 이용 항목</span> : 문의내용, 이메일
        주소, 휴대전화번호, 생년월일, 성별, 거주지역(구까지), 설문번호, 휴대폰
        기기 정보
        <br />
        <span className="terms-bold terms-accentuation">
          보유 및 이용 기간 : 전자상거래 등에서의 소비자 보호에 관한 법률에 따라
          3년
        </span>
      </p>
      <br />
      ※ 문의 내용 별 수집하는 항목이 상이할 수 있습니다.
      <br />※ 위 개인정보 수집에 대한 동의를 거부할 권리가 있으며, 동의 거부
      시에는 서비스 이용이 제한될 수 있습니다.
    </div>
  ),
};
