import styled from 'styled-components';

export const Wrapper = styled.footer`
  background-color: #454f5d;
`;

export const Inner = styled.article`
  max-width: 1140px;
  margin: 0 auto;
  padding: 95px 0 140px 0;

  .menu {
    display: flex;
    align-items: center;
    padding-bottom: 50px;
    margin-bottom: 56px;
    border-bottom: 1px solid #999;
  }
  .linkWrapper {
    display: flex;
    margin-left: 80px;

    li {
      padding: 0 40px;
      border-right: 2px solid #c4c4c4;

      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        border-right: none;
      }
      &:last-child a {
        font-weight: 700;
        opacity: 1;
      }
    }

    a {
      text-decoration: none;
      font-weight: 400;
      opacity: 0.8;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: -0.4px;
      color: #fff;
    }
  }
  .copyright,
  .address {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: rgba(255, 255, 255, 0.64);
  }

  @media screen and (max-width: 1240px) {
    padding: 40px 16px 64px;
    .menu {
      display: block;
      padding: 0;
      border: none;
      margin-bottom: 32px;
    }
    .linkWrapper {
      display: block;
      margin: 32px 0 0;

      li {
        border: none;
        padding: 0;
        margin-top: 20px;

        &:first-of-type {
          margin-top: 0;
        }
      }
      a {
        font-size: 13px;
        line-height: 16px;
      }
    }
    .copyright,
    .address {
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.02em;
    }
  }
`;
