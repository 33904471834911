import GNB from 'components/main/GNB';
import Top from 'components/main/Top';
import Service from 'components/main/Service';
import Security from 'components/main/Security';
import Footer from 'components/main/Footer';

const Index = () => {
  return (
    <>
      <GNB />
      <Top />
      <Service />
      <Security />
      <Footer />
    </>
  );
};

export default Index;
