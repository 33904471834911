import React from 'react';
import styled from 'styled-components';

const PrivacyTermsApp: React.FC = () => {
  return (
    <PrivacyTermsAppWrapper>
      <ol className="number-list">
        <li>수집 항목 및 보유기간</li>
        <Table>
          <Tr className="thead">
            <Tc>
              <Td>구분</Td>
            </Tc>
            <Tc>
              <Td>수집 항목(필수)</Td>
            </Tc>
            <Tc>
              <Td>보유 기간</Td>
            </Tc>
          </Tr>
          <Tr>
            <Tc>
              <Td>개인 식별 및 본인 여부</Td>
              <Td>기본 설문 서비스 제공</Td>
              <Td>문의 접수 및 처리</Td>
            </Tc>
            <Tc>
              <Td>휴대전화번호, 생년월일, 성별, 거주지역, 직업</Td>
            </Tc>
            <Tc>
              <Td className="retention-period-td">{`회원 탈퇴 즉시 파기\n\n부정이용 방지를 위하여 6개월 동안 보관(휴대전화번호) 후 파기\n\n1년간 이용내역이 없는 경우 즉시 파기\n\n사용제한회원 등록 시 30일 후 파기`}</Td>
            </Tc>
          </Tr>
          <Tr>
            <Tc>
              <Td>
                서비스 방문 및 이용 기록 분석, 부정이용 방지 등을 위한 기록관리
              </Td>
            </Tc>
            <Tc>
              <Td>
                서비스 이용 기록, 접속 로그, 광고 식별자(IDFA 또는 ADID를 통해{' '}
                개인을 식별할 수 있는 경우에 한함)
              </Td>
            </Tc>
            <Tc>
              <Td className="retention-period-td">
                회원 탈퇴 즉시 또는 이용 목적 달성 즉시 파기
              </Td>
            </Tc>
          </Tr>
        </Table>
        <li>수집 이용 목적</li>
        <ul className="dot-list">
          <li>{`-\t회원 가입 및 회원 관리`}</li>
          <li>
            {`-\t서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산`}
          </li>
          <li>{`-\t문의 접수 및 처리`}</li>
          <li>{`-\t서비스 개선, 광고 및 마케팅에 활용`}</li>
        </ul>
      </ol>
      <p className="last-paragraph">
        {`위 개인정보 수집 및 이용에 동의하지 않으실 수 있으며, 동의하지 않는 경우 회원가입 및 서비스 이용이 제한됩니다.\n그 밖의 사항은 개인정보처리방침에 따릅니다.`}
      </p>
    </PrivacyTermsAppWrapper>
  );
};

export default PrivacyTermsApp;

const Td = styled.div`
  flex: 1;
  padding: 5px;
  text-align: center;

  && {
    font-size: 12px;
  }

  &.retention-period-td {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  & + & {
    border-top: 1px solid #000000;
  }
`;

const Tc = styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    border-left: 1px solid #000000;
  }
`;

const Tr = styled.div`
  display: flex;
  flex-direction: row;

  &.thead {
    background-color: #d9d9d9;
  }

  & > ${Tc} {
    flex: 1;
  }

  & + & {
    border-top: 1px solid #000000;
  }
`;

const Table = styled.div`
  margin: 12px 0 36px;
  border: 1px solid #000000;
  width: 100%;
`;

const PrivacyTermsAppWrapper = styled.section`
  margin: 21% 11% 100px;

  * {
    font-size: 13px;
    line-height: 18px;

    color: #000000;
    word-break: break-all;
    white-space: pre-wrap;
  }

  .number-list {
    list-style-type: decimal;
    list-style-position: inside;
  }

  .last-paragraph {
    margin-top: 48px;
  }
`;
