import { PROFILE_ANSWER_HISTORY } from 'constant/ProfileAnswerTerms';
import AppTermsFrame from './AppTermsFrame';

const ProfileAnswerTerms = () => {
  return (
    <AppTermsFrame
      title="프로필 설문에 대한 개인정보 수집 및
      이용 동의 (선택)"
      url="service-terms"
      historyData={PROFILE_ANSWER_HISTORY}
    />
  );
};

export default ProfileAnswerTerms;
