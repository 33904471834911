import styled from 'styled-components';

export const Wrapper = styled.section`
  margin: 21% 11% 100px;

  body {
    overflow: scroll;
    position: inherit;
  }

  ol li {
    list-style: decimal;
  }

  .title-label {
    font-size: 16px;
    font-weight: 300;
    color: #000;
  }

  .terms-body {
    white-space: pre-line;
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: -0.5px;
    text-align: justify;
    color: #000000;
  }

  .terms-body > .first_level {
    margin-top: 40px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.43;
    color: #000000;
  }

  .terms-body > .second_level {
    margin-top: 25px;
    margin-bottom: 0;
    color: #000000;
  }

  .terms-accentuation {
    font-size: 16px !important;
  }

  .terms-bold {
    font-size: 12px;
    color: #000000;
    font-weight: 600;
  }

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: left;
  }

  table th,
  table td {
    padding: 5px;
    font-size: 12px;
    border: 1px solid #647887;
    word-break: break-all;
  }

  .terms,
  .sub {
    white-space: normal;
    margin: 0;
    padding-left: 15px;
    line-height: 2;
  }

  .listToggleButton {
    position: relative;
    cursor: pointer;
    padding: 0 0 0 15px;
    margin-top: 20px;
    display: inline-block;
    align-items: center;
    background-color: transparent;
    border: none;
    color: #000;
  }
  .listToggleButton::before {
    position: absolute;
    left: 0;
    top: 2px;
    display: inline-block;
    content: '';
    width: 0;
    height: 0;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    border-left: 5px solid #000;
    border-right: 5px solid transparent;
  }
  .open::before {
    transform: rotate(90deg);
    top: 5px;
  }
  .versionList {
    margin-top: 10px;
  }
  .versionList li {
    margin-top: 5px;
  }
  .versionList li:first-of-type {
    margin-top: 0;
  }
  .versionButton {
    cursor: pointer;
    color: #1c6eff;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    font-size: 12px;
  }
`;

export const Td = styled.div`
  flex: 1;
  padding: 12px 8px;

  && {
    font-size: 12px;
    color: #000000;
    font-weight: 400;
  }

  &.retention-period-td {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  & + & {
    border-top: 0.5px solid #cfcfcf;
  }
`;

export const Tc = styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    border-left: 0.5px solid #cfcfcf;
  }
`;

export const Tr = styled.div`
  display: flex;
  flex-direction: row;

  &.thead {
    background-color: #ececec;

    & > ${Tc} > ${Td} {
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
    }
    color: #000000;
  }

  & > :first-child {
    flex: 1;
  }

  & > :last-child {
    flex: 2;
  }

  & + & {
    border-top: 0.5px solid #cfcfcf;
  }
`;

export const Table = styled.div`
  margin: 12px 0 36px;
  border: 0.5px solid #cfcfcf;
  border-radius: 4px;
  width: 100%;
  overflow: hidden;
`;

export const PrivacyTermsAppWrapper = styled.section`
  margin: 20px 24px;

  * {
    font-size: 13px;
    line-height: 18px;

    color: #000000;
    word-break: break-all;
    white-space: pre-wrap;
  }

  .number-list {
    list-style-type: decimal;
    list-style-position: inside;
  }

  .last-paragraph {
    margin-top: 48px;
  }

  b {
    font-weight: 700;
    font-size: 14px;
  }
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
`;

export const SubTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;

  color: #000000;

  margin-top: 20px;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 12px;
  margin-top: 20px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #ececec;
  margin: 20px 0px;
`;

export const BoxContents = styled.div`
  display: inline-block;
  padding: 8px 12px;

  background: #ececec;
  border-radius: 4px;

  margin-top: 8px;

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: #000000;
`;
